import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";

import { Spin, Modal, Form, Input } from "antd";

import {
  createProject,
  setOrder,
} from "../../redux/persist/companiesActions";
import { FORM_LAYOUT } from "../../constants/constantsUI";
import { openCreateProjectModal } from "../../redux/projects/projectActions";

class CreateProjectModal extends Component {
  projectFormRef = React.createRef();
  render() {
    const {
      companies,
      setOrder,
      openCreateProjectModal,
      isCreateProjectModalOpen,
      isLoading,
    } = this.props;

    const { companyDetail, orderData } = companies;
    const { projects } = companyDetail;
    const projectIds = _.map(projects, "id");

    if (orderData && !projectIds.includes(orderData.project_id)) {
      setOrder(null);
    }

    return (
      <Modal
        title={"Create Project"}
        visible={isCreateProjectModalOpen}
        onOk={this.onProjectFinish}
        onCancel={() => openCreateProjectModal(false)}
        destroyOnClose={true}
        cancelText="cancel"
        okText="create"
        okButtonProps={{
          disabled: isLoading,
          className: "bitreel-button-primary",
        }}
        cancelButtonProps={{ disabled: isLoading, className: "bitreel-button" }}
        closable={!isLoading}
        maskClosable={!isLoading}
      >
        <Spin spinning={isLoading}>
          <Form
              layout={"vertical"}
            name="project"
            onFinish={this.onProjectFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.projectFormRef}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input project name - unique per company!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }

  onProjectFinish = async () => {
    const {
      createProject,
      auth,
      companies,
      openCreateProjectModal,
    } = this.props;
    const {companyDetail} = companies;
    const data = await this.projectFormRef.current.validateFields();

    data["type_id"] = 1; // TODO: when we have many types, get rid of this hardcoded value
    data["company_id"] = companyDetail.id;
    createProject(auth().token, data);
    openCreateProjectModal(false);
  };

  onFinishFailed = () => {
    console.log("Bad input!");
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
    isCreateProjectModalOpen: state.projects.isCreateProjectModalOpen,
    isLoading: state.projects.isCreateProjectLoading,
  };
};

export default connect(mapStateToProps, {
  openCreateProjectModal,
  createProject,
  setOrder,
})(withRouter(CreateProjectModal));
