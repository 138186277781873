import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, Row, Typography } from "antd";
import { connect } from "react-redux";
import ReferenceMaterialComponent from "./containers/ReferenceMaterial";
import "@google/model-viewer";
import { Content } from "antd/lib/layout/layout";
import "./fileLocker.less";
import {getFileLockerRequest} from "../../redux/persist/companiesActions";

const { Title, Text } = Typography;

class FileLocker extends Component {
  componentDidMount() {
    this.props.getFileLockerRequest(this.props.auth().token, this.props.companies.itemData.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.companies.itemData.id !== prevProps.companies.itemData.id) {
      this.props.getFileLockerRequest(this.props.auth().token, this.props.companies.itemData.id);
    }
  }

  render() {
    const { auth } = this.props;
    return (
      <Content className="fileLockerContent">
        <Row justify="center">
          <Title>File Locker</Title>
        </Row>
        <Row justify="center">
          <Text className="title" level={4}>
            (Model Reference Materials)
          </Text>
        </Row>
        <Row justify="center">
          <Col span={8}>
            <Title level={5}>Product Images</Title>
            <ReferenceMaterialComponent
              auth={auth}
              label="Product Images"
              name="product_image"
              message="Please upload any additional product images"
            />
          </Col>
          <Col span={3}>
            <span className="verticalLine"></span>
          </Col>
          <Col span={8}>
            <Title level={5}>3D Reference Files</Title>
            <ReferenceMaterialComponent
              auth={auth}
              label="Reference Files"
              name="reference_file"
              message="Please upload any 3D reference files"
            />
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
  };
};

export default connect(mapStateToProps, {
  getFileLockerRequest,
})(withRouter(FileLocker));
