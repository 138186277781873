import produce from "immer";
import { filter } from "lodash";

const INITIAL_STATE = {
  openUploadNewModel: false,
  isUpdateLoading: false,
  isSubmitVersionLoading: false,
  isSubmitNotesLoading: false,
  openProductDetailModal: false,
  allHotSpots: [],
  selectHotSpot: null,
  // cursorStyle: "",
  isAnnotationLoading: false,
  isFetchingItemLoading: false,
  isModelEmpty: false,
  showedHotspotRow: null,
  isEditProductModalOpen: false,
  isThumbnailLoading: false,
  isCreateProductModalOpen: false,
  isCreateProductLoading: false
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_CREATE_PRODUCT_LOADING":
      draft.isCreateProductLoading = action.isCreateProductLoading;
      break;
    case "IS_CREATE_PRODUCT_MODAL_OPEN":
      draft.isCreateProductModalOpen = action.isCreateProductModalOpen
          break;
    case "IS_THUMBNAIL_LOADING":
      draft.isThumbnailLoading = action.isThumbnailLoading
          break;
    case "OPEN_EDIT_PRODUCT_MODAL":
      draft.isEditProductModalOpen = action.isEditProductModalOpen
          break;
    case "SHOW_HOTSPOT_ROW":
      draft.showedHotspotRow = action.showedHotspotRow
          break;
    case "EMPTY_MODEL":
      draft.isModelEmpty = action.isModelEmpty
          break;
    case "IS_FETCHING_ITEM_LOADING":
       draft.isFetchingItemLoading = action.isFetchingItemLoading
          break;
    case "IS_ANNOTATION_LOADING":
      draft.isAnnotationLoading = action.isAnnotationLoading;
      break;
    case "OPEN_UPDATE_NEW_MODAL":
      draft.openUploadNewModel = action.isOpen;
      break;
    case "IS_UPDATE_LOADING":
      draft.isUpdateLoading = action.isUpdateLoading;
      break;
    case "IS_SUBMIT_VERSION_LOADING":
      draft.isSubmitVersionLoading = action.isSubmitVersionLoading;
      break;
    case "IS_SUBMIT_NOTES_LOADING":
      draft.isSubmitNotesLoading = action.isSubmitNotesLoading;
      break;
    case "OPEN_PRODUCT_DETAIL_MODAL":
      draft.openProductDetailModal = action.isOpen;
      break;
    case "ADD_HOTSPOT":
      const { domHotSpot } = action;
      let draftHotSpots = [];

      if (draft["allHotSpots"]) {
        draft["allHotSpots"].forEach((obj) => {
          draftHotSpots.push(obj);
        });
      }
      draftHotSpots.push(domHotSpot);
      draft["allHotSpots"] = draftHotSpots;
      break;
    case "SELECT_HOTSPOT":
      const { selectHotSpot } = action;
      draft["selectHotSpot"] = selectHotSpot;
      break;
    case "REMOVE_HOTSPOT":
      const { oldHotSpot } = action;
      draft["allHotSpots"] = filter(draft["allHotSpots"], (hotspot) => {
        return !(hotspot.slot === oldHotSpot.slot);
      });
      break;
    default:
      break;
  }

  return draft;
});
