import * as React from "react";

function NoImageSvg(props) {
  return (
    <svg
      width={54}
      height={45}
      viewBox="0 0 54 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.767.158h-33.6c-5.376 0-9.8 4.368-9.8 9.8v24.64c0 5.376 4.368 9.8 9.8 9.8h33.6c5.376 0 9.8-4.368 9.8-9.8V9.958c0-5.432-4.368-9.8-9.8-9.8zm-33.6 2.856h33.6c3.808 0 6.944 3.136 6.944 6.944v20.384L38.223 16.006c-.84-.952-2.016-1.512-3.248-1.512-1.232 0-2.464.56-3.304 1.512l-10.92 12.488L17 24.406c-.784-.84-1.848-1.344-3.024-1.344-1.12 0-2.24.504-3.024 1.344l-7.728 8.512V9.958a6.907 6.907 0 016.944-6.944zm33.6 38.528h-33.6c-3.08 0-5.656-2.016-6.608-4.816l9.464-10.416c.448-.504 1.344-.504 1.848 0l4.816 5.264c.28.28.672.392 1.064.448a1.38 1.38 0 001.064-.504l11.928-13.72c.28-.336.728-.504 1.176-.504.448 0 .84.168 1.12.504l14.672 16.8c0 3.864-3.136 6.944-6.944 6.944z"
        fill="#CCC"
      />
      <path
        d="M13.528 18.638c2.968 0 5.432-2.408 5.432-5.432 0-2.968-2.408-5.432-5.432-5.432a5.402 5.402 0 00-5.432 5.432 5.402 5.402 0 005.432 5.432zm0-8.008c1.4 0 2.576 1.176 2.576 2.576 0 1.4-1.176 2.576-2.576 2.576-1.4 0-2.576-1.176-2.576-2.576a2.57 2.57 0 012.576-2.576z"
        fill="#CCC"
      />
    </svg>
  );
}

export default NoImageSvg;
