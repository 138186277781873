import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  getMobileOperatingSystem,
  launchAndroidAR,
  launchIOSQuickLookAR,
} from "../utils/arUtils";
import envFile from "../../assets/studio7Low.hdr";
import "@google/model-viewer";
import logo from "../../assets/logo.png";

class QRViewer extends Component {
  componentDidMount() {
    if (getMobileOperatingSystem() === "unknown") {
    } else {
      const urlParams = new URLSearchParams(window.top.location.search);
      if (
        urlParams.has("showAR") &&
        (urlParams.has("usdzModel") || urlParams.has("glbModel")) &&
        urlParams.get("showAR")
      ) {
        if (getMobileOperatingSystem() === "iOS") {
          launchIOSQuickLookAR(urlParams.get("usdzModel"));
        }
        if (getMobileOperatingSystem() === "Android") {
          launchAndroidAR(urlParams.get("glbModel"));
        }
      }
    }
  }

  render() {
    const urlParams = new URLSearchParams(window.top.location.search);
    return (
        <model-viewer
            ar
            ar-modes="webxr scene-viewer quick-look"
            onContextMenu={(e) => e.preventDefault()}
            camera-orbit="-20deg 60deg 140%"
            max-camera-orbit="auto auto 200%"
            style={{
              width: "100vw",
              height: "100vh",
            }}
            src={urlParams.get("glbModel").substring(0, urlParams.get("glbModel").lastIndexOf(".") + 1) + '_compressed.glb'}
            alt="A 3D model"
            auto-rotate
            ios-src={urlParams.get("usdzModel")}
            camera-controls
            autoplay
            data-js-focus-visible
            quick-look-browsers="safari chrome"
            environment-image={envFile}
        >
          <img style={{margin: 20}} src={logo}/>
        </model-viewer>
        )

  }
}
export default withRouter(QRViewer);
