import React, { Component } from "react";
import { withRouter } from "react-router";
import { getAllUsers } from "../redux/users/usersActions";
import {
  getAllCompanies,
  getAllStatuses,
} from "../redux/persist/companiesActions";
import { connect } from "react-redux";
import { Layout } from "antd";
import { LOGIN_URL, NO_AUTH_URLS } from "./../constants/constants";
import { sharedInterface } from "@Bitreel/portal-login";
import "../styles/MainAppContainer.less";
import SiteMap from "./siteMap";

class MainAppContainer extends Component {

  componentDidMount() {
    const { getAllUsers, getAllCompanies, getAllStatuses, auth } = this.props;
    if (!auth().token && !NO_AUTH_URLS.includes(window.location.pathname)) {
      window.location.href = window.location.origin + LOGIN_URL;
    }
    if (auth().token && !NO_AUTH_URLS.includes(window.location.pathname)) {
      getAllUsers(auth().token);
      getAllCompanies(auth().token);
      getAllStatuses(auth().token);
    }
  }

  render() {
    const { companies, users, auth } = this.props;
    return (
      <>
        <Layout className="layout" style={{ paddingTop: 60 }}>
          <SiteMap companies={companies} users={users} auth={auth} />
        </Layout>
      </>
    );
  }
}

const mapStateToProps = ({ users, companies }) => {
  return {
    auth: sharedInterface,
    users,
    companies,
  };
};

export default connect(mapStateToProps, {
  getAllUsers,
  getAllCompanies,
  getAllStatuses,
})(withRouter(MainAppContainer));
