export const checkCurrency = (_, value) => {
    if(!value) {
        return Promise.reject(new Error('Invalid Price!'));
    }

    // Decimal and commas optional
    if(value.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/)) {
        return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid Price!'));
}


export const checkURL = (_, value) => {
    // Decimal and commas optional
    if(!value || value.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) ) {
        return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid URL!'));
}
