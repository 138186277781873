import companiesDataReducer from "./persist/companiesReducer";
import usersReducer from "./users/usersReducer";
import companiesReducer from "./companies/companiesReducer";
import { combineReducers } from "redux";
import adminReducer from "./products/adminReducer";
import productReducers from "./products/productReducers";
import orderReducers from "./orders/orderReducers";
import projectReducers from "./projects/projectReducers";

export default combineReducers({
  product: productReducers,
  users: usersReducer,
  companiesData: companiesDataReducer,
  companies: companiesReducer,
  admin: adminReducer,
  orders: orderReducers,
  projects: projectReducers,
});
