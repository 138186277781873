import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

import { Menu, Button, Spin, Card, Divider } from "antd";
import Sider from "antd/lib/layout/Sider";

import { setOrder } from "../../redux/persist/companiesActions";
import {openCreateOrderModal, updateSelctedOrder} from "../../redux/orders/orderActions";
import {openCreateProjectModal, setOpenKeys} from "../../redux/projects/projectActions";

import "./../../styles/SiderMenu.less";

const { SubMenu } = Menu;

class SiderMenu extends Component {
  handleClick = (e) => {
    const orderId = e.key;
    const { companyDetail, setOrder } = this.props;

    _.forEach(companyDetail.projects, (project) => {
      const targetOrder = _.filter(project.orders, (obj) => obj.id == orderId);
      if (targetOrder.length) {
        setOrder(targetOrder[0]);
        this.props.updateSelctedOrder([_.has(targetOrder[0], 'id') ? targetOrder[0].id.toString() : ""])
      }
    });
  };

  getOrders(project) {
    const orderItems = [];
    _.forEach(project.orders, (order) => {
      orderItems.push(
        <Menu.Item
          key={order.id}
          style={{ backgroundColor: "#eee:hover" }}
          className="listItem"
        >
          <span className="listItemBackground">{order.name}</span>
          <span className="listItemDecoration" />
        </Menu.Item>
      );
    });
    return orderItems;
  }

  getMenuItems() {
    const { projects } = this.props.companyDetail;
    const menuItems = [];

    _.forEach(projects, (project) => {
      menuItems.push(
        <SubMenu key={project.id} title={project.name}>
          {this.getOrders(project)}
        </SubMenu>
      );
    });

    return menuItems;
  }

  componentDidMount() {
    this.props.orderData &&
     this.props.setOpenKeys([_.has(this.props.orderData, 'project_id') ? this.props.orderData.project_id.toString(): ""]) &&
     this.props.updateSelctedOrder(_.has(this.props.orderData, 'project_id') ? this.props.orderData.id.toString(): "")
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.orderData &&  prevProps.isLoading !== this.props.isLoading) {
      this.props.orderData &&
      this.props.setOpenKeys([_.has(this.props.orderData, 'project_id') ? this.props.orderData.project_id.toString() : ""]) &&
      this.props.updateSelctedOrder(_.has(this.props.orderData, 'project_id') ? this.props.orderData.id.toString(): "")
    }
  }

  render() {
    const { openCreateOrderModal, openCreateProjectModal } = this.props;
    return (
      <Sider width={250} className="siderMenu">
        <Card bordered={false} className="cardButtonsMenu">
          <h2>3D Models</h2>
          <Button
            loading={this.props.isLoading}
            className="bitreel-button"
            onClick={() => openCreateProjectModal(true)}
          >
            CREATE NEW PROJECT
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            loading={this.props.isLoading}
            className="bitreel-button"
            onClick={() => openCreateOrderModal(true)}
          >
            CREATE NEW ORDER
          </Button>
          <span className="horizontalLine" />
        </Card>

        <Spin spinning={this.props.isLoading} tip="Loading...">
          <Menu
            className="leftMenu"
            onClick={this.handleClick}
            mode="inline"
             selectedKeys={this.props.selectedOrder}
            onOpenChange={(openKeys) => this.props.setOpenKeys([openKeys[openKeys.length - 1]])}
            openKeys={this.props.openedProjects}
            style={{
              width: 250,
            }}
          >
            {this.getMenuItems()}
          </Menu>
        </Spin>
      </Sider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companyDetail: state.companiesData.companyDetail,
    isLoading: state.companies.isFetchingCompanyInfo,
    orderData: state.companiesData.orderData,
    openedProjects: state.projects.openedProjects,
    selectedOrder: state.orders.selectedOrder
  };
};

export default connect(mapStateToProps, {
  setOrder,
  setOpenKeys,
  updateSelctedOrder,
  openCreateOrderModal,
  openCreateProjectModal,
})(withRouter(SiderMenu));
