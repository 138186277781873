import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";

import { Card, Layout, Menu } from "antd";

import { setOrder } from "../../redux/persist/companiesActions";

import "./../../styles/SiderMenu.less";

const { Sider } = Layout;

class SiderMenu extends Component {
  render() {
    const { auth } = this.props;
    let email = "<user email>";
    if (auth != null) {
      email = auth().user.email;
    }

    return (
      <Sider width={250} className="siderMenu">
        <Card bordered={false}>
          <h2>Companies</h2>
          <p>{email}</p>
        </Card>

        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          className="listItem"
        >
          <Menu.Item key="1" style={{ background: "#000 !important" }}>
            <span className="listItemBackground adminListItemBackground">
              Manage Companies & Users
            </span>
            <span className="listItemDecoration" />
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = ({ companies }) => {
  return {
    companies,
  };
};

export default connect(mapStateToProps, {
  setOrder,
})(withRouter(SiderMenu));
