import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import {
  HOME_URL,
  MODEL_INFO_URL,
  MODEL_SELECT_URL,
  QR_VIEW_URL,
} from "../constants/constants";
import Home from "../home/Home";
import QRViewer from "./QRViewer";
import ProductInfo from "../productView/productInfo";
import ProductsList from "../productsListView/productsList";


class SiteMap extends Component {
  render() {
    const { companies, users, auth } = this.props;

    return (
      <Switch>
        <Route
          render={(props) => <ProductInfo {...props} auth={auth} />}
          path={MODEL_INFO_URL}
        />
        <Route
          render={(props) => (
            <ProductsList {...props} auth={auth} />
          )}
          path={MODEL_SELECT_URL}
        />
        <Route render={(props) => <QRViewer {...props} />} path={QR_VIEW_URL} />
        <Route
          render={(props) => (
            <Home {...props} auth={auth} users={users} companies={companies} />
          )}
          path={HOME_URL}
        />
      </Switch>
    );
  }
}

export default withRouter(SiteMap);
