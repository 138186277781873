import {
  SET_COMPANIES,
  UPDATE_COMPANY,
  SET_COMPANY_DETAIL,
  SET_ORDER,
  CREATE_ORDER,
  CREATE_PROJECT,
  CREATE_COMPANY,
  SET_ITEM,
  SET_STATUSES,
  SET_FILE_LOCKER,
  SET_ACTIVE_COMMENT_ID,
  SET_HOTSPOTS,
} from "./companiesConstants";

import ApiService from "../../service/apiService";
import S3Service from '../../service/s3Service';
import {filter} from 'lodash';
import { updateUser } from "../users/usersActions";
import { isFetchingItemLoading } from "../products/productActions";
import { isCreateOrderLoading } from "./../orders/orderActions";
import { isCreateProjectLoading } from "./../projects/projectActions";
import {
  isFetchingCompanies,
  isFetchingCompanyInfo,
} from "../companies/companiesAction";


export const setFileLocker = (fileLockerData) => {
  return {
    type: SET_FILE_LOCKER,
    fileLockerData,
  };
};

export const setActiveCommentId = (activeCommentId) => {
  return {
    type: SET_ACTIVE_COMMENT_ID,
    activeCommentId,
  };
};

export const setHotSpots = (allHotSpots) => {
  return {
    type: SET_HOTSPOTS,
    allHotSpots,
  };
};

export const selectHotSpotHandler = (hotspot) => async (dispatch) => {
  const viewer = document.getElementById("threeDModel");
  for (let i = 0; i < viewer.children.length; i++) {
    viewer.children[i].classList.remove("selected");
  }
  hotspot.classList.add("selected");
  dispatch(selectHotSpot(hotspot));
};

export const updateCompany = (companyId, companyData) => {
  return {
    type: UPDATE_COMPANY,
    companyId,
    companyData,
  };
};

export const createNewCompany = (newCompanyData) => {
  return {
    type: CREATE_COMPANY,
    newCompanyData,
  };
};

export const setStatuses = (statuses) => {
  return {
    type: SET_STATUSES,
    statuses,
  };
};

export const setCompanies = (companies) => {
  return {
    type: SET_COMPANIES,
    companies,
  };
};

export const setCompanyDetail = (companyDetail) => {
  return {
    type: SET_COMPANY_DETAIL,
    companyDetail,
  };
};

export const setOrder = (orderData) => {
  return {
    type: SET_ORDER,
    orderData,
  };
};

export const setItem = (itemData) => {
  return {
    type: SET_ITEM,
    itemData,
  };
};

export const createNewOrder = (newOrderData) => {
  return {
    type: CREATE_ORDER,
    newOrderData,
  };
};

export const createNewProject = (newProjectData) => {
  return {
    type: CREATE_PROJECT,
    newProjectData,
  };
};

export const createCompany = (token, companyData) => async (dispatch) => {
  try {
    const response = await ApiService.postCompany(token, companyData);
    const { data } = response;
    dispatch(createNewCompany(data));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const createOrder = (token, orderData) => async (dispatch) => {
  try {
    dispatch(isCreateOrderLoading(true));
    const response = await ApiService.postOrder(token, orderData);
    const { data } = response;
    dispatch(createNewOrder(data));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isCreateOrderLoading(false));
  }
};

export const createProject = (token, projectData) => async (dispatch) => {
  try {
    dispatch(isCreateProjectLoading(true));
    const response = await ApiService.postProject(token, projectData);
    const { data } = response;
    dispatch(createNewProject(data));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isCreateProjectLoading(false));
  }
};

export const getAllCompanies = (token) => async (dispatch) => {
  try {
    dispatch(isFetchingCompanies(true));
    const response = await ApiService.getCompanies(token);
    const { data } = response;
    const companies = {};

    data.forEach((element) => {
      companies[element.id] = element;
    });

    dispatch(setCompanies(companies));
    return companies;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isFetchingCompanies(false));
  }
};

export const getAllStatuses = (token) => async (dispatch) => {
  try {
    const response = await ApiService.getItemStatuses(token);
    const { data } = response;
    const statuses = {};

    data.forEach((element) => {
      statuses[element.id] = element;
    });

    dispatch(setStatuses(statuses));
    return statuses;
  } catch (error) {
    console.warn(error);
  }
};

export const getCompanyRequest = (token, companyId, projectId, orderId) => async (dispatch) => {
  try {
    dispatch(isFetchingCompanyInfo(true));
    const response = await ApiService.getCompany(token, companyId);
    const { data } = response;
    dispatch(setCompanyDetail(data));

    if (data.projects.length && data.projects[0].orders.length) {
      const project = filter(data.projects, (project) => {
        return project.id.toString() === projectId
      })
      const order = filter(project[0] ? project[0].orders : [], (order) => {
        return order.id.toString() === orderId
      })
      dispatch(setOrder(order && order.length !== 0 ? order[0] : data.projects[0].orders[0]));
    }

    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isFetchingCompanyInfo(false));
  }
};

export const updateCompanyRequest = (token, companyId, companyData) => async (
  dispatch
) => {
  try {
    const response = await ApiService.patchCompany(
      token,
      companyId,
      companyData
    );
    const { data } = response;
    dispatch(updateCompany(companyId, data));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const addUserToCompany = (token, userId, companyId) => async (
  dispatch
) => {
  try {
    const response = await ApiService.postUserCompany(token, userId, companyId);
    const { data } = response;
    const { user, company } = data;

    dispatch(updateCompany(company.id, company));
    dispatch(updateUser(user.id, user));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const removeUserFromCompany = (token, userId, companyId) => async (
  dispatch
) => {
  try {
    const response = await ApiService.deleteUserCompany(
      token,
      userId,
      companyId
    );
    const { data } = response;
    const { user, company } = data;

    dispatch(updateCompany(company.id, company));
    dispatch(updateUser(user.id, user));
    return data;
  } catch (error) {
    console.warn(error);
  }
};



export const deleteItem = (token, itemId) => async (dispatch) => {
  try {
    const response = await ApiService.deleteItem(token, itemId);
    const { data } = response;
    dispatch(getCompanyRequest(token, data.company_id));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const getItemRequest = (token, itemId) => async (dispatch) => {
  try {
    dispatch(isFetchingItemLoading(true));
    const response = await ApiService.getItem(token, itemId);
    const { data } = response;
    dispatch(setItem(data));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isFetchingItemLoading(false));
  }
};

export const deleteFileLocker = (token, itemId, fileType, fileName) => async (
  dispatch
) => {
  try {
    const deleteResponse = await ApiService.deleteFileLocker(token, itemId, {
      key: fileType,
      file_name: fileName,
    });
    const { data } = deleteResponse;
    dispatch(setFileLocker(data));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const getFileLockerRequest = (token, itemId) => async (dispatch) => {
  try {
    const response = await ApiService.getFileLocker(token, itemId);
    const { data } = response;
    dispatch(setFileLocker(data));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const uploadFileLocker = (
  token,
  itemId,
  fileData,
  fileType,
  fileName
) => async (dispatch) => {
  try {
    const s3UrlResponse = await ApiService.postFileLocker(token, itemId, {
      key: fileType,
      file_name: fileName,
    });
    const s3UrlData = s3UrlResponse.data;
    const { url, fields } = s3UrlData;
    const uploadFormData = new FormData();

    Object.keys(fields).forEach((key) => {
      uploadFormData.append(key, fields[key]);
    });

    uploadFormData.append("file", fileData);

    const s3UploadResponse = await S3Service.postToS3(url, uploadFormData);
    dispatch(getFileLockerRequest, itemId);
    return s3UploadResponse.data;
  } catch (error) {
    console.warn(error);
  }
};
