import { notification } from "antd";
import {forEach} from 'lodash';

export default () => {
  const modelViewer = document.getElementById("threeDModel");
  modelViewer.shadowIntensity = 1;
  modelViewer.shadowSoftness = 1;
  const tapDistance = 1;
  let panning = false;
  let panX, panY;
  let startX, startY;
  let lastX, lastY;
  let metersPerPixel;

  const startPan = () => {
    const orbit = modelViewer.getCameraOrbit();
    const { theta, phi, radius } = orbit;
    metersPerPixel =
      (0.1 * radius) / modelViewer.getBoundingClientRect().height;
    panX = [-Math.cos(theta), 0, Math.sin(theta)];
    panY = [
      -Math.cos(phi) * Math.sin(theta),
      Math.sin(phi),
      -Math.cos(phi) * Math.cos(theta),
    ];
    modelViewer.interactionPrompt = "none";
  };

  const movePan = (thisX, thisY) => {
    const dx = (thisX - lastX) * metersPerPixel;
    const dy = (thisY - lastY) * metersPerPixel;
    lastX = thisX;
    lastY = thisY;

    const target = modelViewer.getCameraTarget();
    target.x += dx * panX[0] + dy * panY[0];
    target.y += dx * panX[1] + dy * panY[1];
    target.z += dx * panX[2] + dy * panY[2];
    modelViewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`;
  };

  const recenter = (pointer) => {
    panning = false;
    if (
      Math.abs(pointer.clientX - startX) > tapDistance ||
      Math.abs(pointer.clientY - startY) > tapDistance
    )
      return;
    const rect = modelViewer.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const hit = modelViewer.positionAndNormalFromPoint(x, y);
    modelViewer.cameraTarget =
      hit == null ? "auto auto auto" : hit.position.toString();
  };

  const onPointerUp = (event) => {
    const pointer = event.clientX ? event : event.changedTouches[0];
    if (
      Math.abs(pointer.clientX - startX) < tapDistance &&
      Math.abs(pointer.clientY - startY) < tapDistance
    ) {
      recenter(pointer);
    }
    panning = false;
  };

  modelViewer.addEventListener(
    "mousedown",
    (event) => {
      startX = event.clientX;
      startY = event.clientY;
      panning =
        event.button === 2 || event.ctrlKey || event.metaKey || event.shiftKey;
      if (!panning) return;
      lastX = startX;
      lastY = startY;
      startPan();
      event.stopPropagation();
    },
    true
  );

  modelViewer.addEventListener(
    "touchstart",
    (event) => {
      startX = event.touches[0].clientX;
      startY = event.touches[0].clientY;
      panning = event.touches.length === 2;
      if (!panning) return;

      const { touches } = event;
      lastX = 0.5 * (touches[0].clientX + touches[1].clientX);
      lastY = 0.5 * (touches[0].clientY + touches[1].clientY);
      startPan();
    },
    true
  );

  modelViewer.addEventListener(
    "mousemove",
    (event) => {
      if (!panning) return;

      movePan(event.clientX, event.clientY);
      event.stopPropagation();
    },
    true
  );

  modelViewer.addEventListener(
    "touchmove",
    (event) => {
      if (!panning || event.touches.length !== 2) return;

      const { touches } = event;
      const thisX = 0.5 * (touches[0].clientX + touches[1].clientX);
      const thisY = 0.5 * (touches[0].clientY + touches[1].clientY);
      movePan(thisX, thisY);
    },
    true
  );

  self.addEventListener(
    "mouseup",
    (event) => {
      //recenter(event);
    },
    true
  );

  self.addEventListener(
    "touchend",
    (event) => {
      if (event.touches.length === 0) {
        recenter(event.changedTouches[0]);
      }
    },
    true
  );
};

export const enterFullscreen = () => {
  const modelElement = document.getElementById("threeDModel");

  if (modelElement.requestFullscreen) {
    modelElement.requestFullscreen();
  } else if (modelElement.mozRequestFullScreen) {
    /* Firefox */
    modelElement.mozRequestFullScreen();
  } else if (modelElement.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    modelElement.webkitRequestFullscreen();
  } else if (modelElement.msRequestFullscreen) {
    /* IE/Edge */
    modelElement.msRequestFullscreen();
  }
};

export const errorLoadListener = (type, callback) => {
  const viewer = document.getElementById("threeDModel");
  if(type === "add") {
    viewer.addEventListener("error", callback);
  }
};

export const fullScreenListener = (type, callback) => {
  if(type === "add") {
    document.addEventListener('fullscreenchange', callback);
    document.addEventListener('webkitfullscreenchange', callback);
    document.addEventListener('mozfullscreenchange', callback);
    document.addEventListener('MSFullscreenChange', callback);
  } else if(type === "remove") {
    document.removeEventListener('fullscreenchange', callback);
    document.removeEventListener('webkitfullscreenchange', callback);
    document.removeEventListener('mozfullscreenchange', callback);
    document.removeEventListener('MSFullscreenChange', callback);
  }

}

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
};


export const addAnnotation = (viewer, annotation, index, type) => {
  const { pX, pY, pZ, nX, nY, nZ } = annotation;
  let domHotSpot = document.createElement("button");

  domHotSpot.slot = `hotspot-${index}`;

  domHotSpot.dataset.position = `${pX} ${pY} ${pZ}`;
  domHotSpot.dataset.normal = `${nX} ${nY} ${nZ}`;
  domHotSpot.classList.add(type);
  domHotSpot.classList.add("hotspot");
  viewer.appendChild(domHotSpot);
  return domHotSpot
}


export const onModelAnnotationClickRemove = (hotSpot) => {
  const viewer = document.getElementById("threeDModel");
  viewer.removeChild(hotSpot);
};


//selectSpot
//callback is used to update select dom redux state
export const onSpotClickCallback = (viewer, domElement, callback) => {
  forEach(viewer.children, (hotspot) => {
    hotspot.classList.remove("selected");
  });
  domElement.classList.add("selected");
  callback(domElement)
};



export const onModelAnnotationClickAdd = (
  event,
  type,
  index,
  viewer,
  updateStateCallback
) => {
  const rect = viewer.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);

  if (positionAndNormal == null) {
    notification.warning({
      message: "Invalid Location",
      description: "Please click on the model itself to add a hotspot",
    });
    throw "Invalid Location";
  }
  const { position, normal } = positionAndNormal;

  let hotspot = {};
  hotspot.pX = position.x;
  hotspot.pY = position.y;
  hotspot.pZ = position.z;

  if (normal != null) {
    hotspot.nX = normal.x;
    hotspot.nY = normal.y;
    hotspot.nZ = normal.z;
  } else {
    hotspot.nX = null;
    hotspot.nY = null;
    hotspot.nZ = null;
  }

  const domHotSpot = addAnnotation(viewer, hotspot, index, type);

  domHotSpot.addEventListener("click", () => {
    onSpotClickCallback(viewer, domHotSpot, updateStateCallback);
  });

  return domHotSpot;
};
