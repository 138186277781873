import React, { Component, useState } from "react";
import { withRouter } from "react-router";
import {
  Input,
  Typography,
  Row,
  Col,
  Switch,
  Tooltip,
  Space,
  Button,
} from "antd";
import { connect } from "react-redux";
const { Title, Text, Paragraph } = Typography;
import "@google/model-viewer";
import { forEach, filter, isEmpty, some } from "lodash";
const { TextArea } = Input;
import {
  addHotSpot,
  createAnnotation,
  createItemNote,
  removeHotSpot,
  selectHotSpot,
  showHotspotRow,
  updateItemVersion,
} from "../redux/products/productActions";
import IncludeSvgUrl from "../../assets/include.svg";
import ExcludeSvgUrl from "../../assets/exclude.svg";
import addCursorUrl from "../../assets/addCursor.svg";
import "../productView/productComment.less";
import {
  onModelAnnotationClickAdd,
  onModelAnnotationClickRemove,
} from "../utils/modelViewerUtil";
import CommentTable from "./commentTable";
import { getItemRequest } from "../redux/persist/companiesActions";
import { LOADED_HOTSPOT, NEW_HOTSPOT } from "../constants/constants";

class ProductComment extends Component {
  constructor(props) {
    super(props);
    this.modelApprovalUpdate = this.modelApprovalUpdate.bind(this);
    this.onAddHotspot = this.onAddHotspot.bind(this);
    this.onRemoveHotspot = this.onRemoveHotspot.bind(this);
    this.placeSpotListener = this.placeSpotListener.bind(this);
    this.onSubmitComment = this.onSubmitComment.bind(this);
  }

  state = {
    comment: "",
  };

  componentWillUnmount() {
    //clear hotspot
    this.props.showHotspotRow(null);
    //remove previous hotspots
    forEach(this.props.allHotSpots, (hotspot) => {
      onModelAnnotationClickRemove(hotspot);
      this.props.removeHotSpot(hotspot);
      this.props.selectHotSpot(null);
    });
    this.props.showHotspotRow(null);
  }

  getStatusId(approvalText) {
    return filter(Object.values(this.props.itemStatuses), (obj) => {
      return obj["name"] === approvalText;
    })[0].id;
  }

  modelApprovalUpdate(checked, event) {
    const { current_version_id, id } = this.props.itemData;
    this.props.updateItemVersion(this.props.auth().token, current_version_id, {
      item_id: id,
      status_id: this.getStatusId(checked ? "Finalized" : "Bitreel Review"),
    });
  }

  placeSpotListener(event) {
    const viewer = document.getElementById("threeDModel");
    try {
      const hotspot = onModelAnnotationClickAdd(
        event,
        NEW_HOTSPOT,
        this.props.allHotSpots.length + 1,
        viewer,
        this.props.selectHotSpot //updateStateCallback
      );
      const { addHotSpot } = this.props;
      addHotSpot(hotspot);
    } catch (e) {
      console.log(e);
    } finally {
      //remove previous comments' hotspot
      forEach(this.props.allHotSpots, (hotspot) => {
        if (hotspot.classList.contains(LOADED_HOTSPOT)) {
          onModelAnnotationClickRemove(hotspot);
          this.props.removeHotSpot(hotspot);
        }
      });
      //remove showed hotspot row
      this.props.showHotspotRow(null);
      viewer.removeEventListener("click", this.placeSpotListener);
    }
  }

  onAddHotspot() {
    //todo: fix out of range bug

    if (this.props.isCommentSubmitLoading || this.props.isModelEmpty) {
      return;
    }

    const viewer = document.getElementById("threeDModel");
    viewer.addEventListener("click", this.placeSpotListener);

    //override cursor style
    viewer.shadowRoot.querySelector(
      "div > div.userInput"
    ).style.cursor = `url('${addCursorUrl}') 36 36, auto`;
  }

  onRemoveHotspot() {
    if (this.props.isCommentSubmitLoading) {
      return;
    }

    try {
      const viewer = document.getElementById("threeDModel");
      const { removeHotSpot, selectHotSpot } = this.props;
      const selected = viewer.getElementsByClassName("selected");
      if (!isEmpty(selected) && selected[0].classList.contains(NEW_HOTSPOT)) {
        removeHotSpot(selected[0]);
        selectHotSpot(null);
        onModelAnnotationClickRemove(selected[0]);
      }
    } catch (e) {}
  }

  onSubmitComment() {
    //submit comment
    this.props
      .createItemNote(this.props.auth().token, {
        note: this.state.comment,
        item_version_id: this.props.itemData.current_version_id,
      })
      .then((resp) => {
        //clear comment in text area
        this.setState({ comment: "" });
        //submit annotations
        let annotationPromises = [];
        if (
          some(this.props.allHotSpots, (obj) =>
            obj.classList.contains(NEW_HOTSPOT)
          )
        ) {
          filter(this.props.allHotSpots, (obj) =>
            obj.classList.contains(NEW_HOTSPOT)
          ).forEach((hotspot) => {
            const [pX, pY, pZ] = hotspot.dataset.position.split(" ");
            const [nX, nY, nZ] = hotspot.dataset.normal.split(" ");
            annotationPromises.push(
              this.props.createAnnotation(this.props.auth().token, {
                pX,
                pY,
                pZ,
                nX,
                nY,
                nZ,
                item_note_id: resp.id,
                item_id: this.props.itemData.id,
              })
            );
          });
        }

        // fetch newest item and remove hotspots
        Promise.all(annotationPromises).then(() => {
          //remove previous hotspots
          forEach(this.props.allHotSpots, (hotspot) => {
            onModelAnnotationClickRemove(hotspot);
            this.props.removeHotSpot(hotspot);
          });
          //remove showed row
          this.props.showHotspotRow(null);

          //fetch
          getItemRequest(this.props.auth().token, this.props.itemData.id);
        });
      });
  }

  render() {
    return (
      <>
        <Row align="middle" className="product_comment_main">
          <Col span={4}>
            {this.props.itemData.current_status === "Created" ? null :
                <Tooltip
                    placement="topLeft"
                    title={
                      <Text>
                        Approving this model will finalize the build process and send
                        notifications to the 3D team.
                      </Text>
                    }
                    color="white"
                >
                  <Switch
                      className="approval_switch"
                      defaultChecked={
                        this.props.itemData.current_status === "Finalized"
                      }
                      loading={this.props.isLoading}
                      disabled={this.props.itemData.current_status === "Created"}
                      checkedChildren="MODEL APPROVED"
                      unCheckedChildren="PENDING APPROVAL"
                      onChange={(checked, event) =>
                          this.modelApprovalUpdate(checked, event)
                      }
                  />
                </Tooltip>
            }
          </Col>
          <Col span={16}>
            <Row align="middle">
              <Space size="large">
                <Title level={4}>Add a Comment:</Title>
                {this.props.itemData.current_status === "Created" ? null: <>
                <a onClick={() => this.onAddHotspot()}>
                  <img className="comment_icon" src={IncludeSvgUrl} />
                  Include Hotspot
                </a>
                <a onClick={() => this.onRemoveHotspot()}>
                  <img className="comment_icon" src={ExcludeSvgUrl} />
                  Remove Hotspot
                </a> </>}
              </Space>
            </Row>
            <Row>
              <TextArea
                disabled={this.props.isCommentSubmitLoading}
                rows={6}
                onChange={(e) => this.setState({ comment: e.target.value })}
                value={this.state.comment}
              />
            </Row>
            <Row justify="end">
              <Button
                className="product_submit_comment bitreel-button-primary"
                loading={this.props.isCommentSubmitLoading}
                disabled={this.state.comment === ""}
                onClick={() => this.onSubmitComment()}
              >
                SUBMIT COMMENT
              </Button>
            </Row>
          </Col>
        </Row>
        <Row
          align={"middle"}
          justify={"center"}
          style={{ backgroundColor: "white" }}
        >
          <CommentTable auth={this.props.auth} />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isModelEmpty: state.product.isModelEmpty,
    itemData: state.companiesData.itemData,
    itemStatuses: state.companiesData.itemStatuses,
    isLoading: state.product.isSubmitVersionLoading,
    isCommentSubmitLoading:
      !!state.product.isSubmitNotesLoading ||
      !!state.product.isAnnotationLoading,
    allHotSpots: state.product.allHotSpots,
  };
};

export default connect(mapStateToProps, {
  updateItemVersion,
  addHotSpot,
  removeHotSpot,
  selectHotSpot,
  createItemNote,
  createAnnotation,
  getItemRequest,
  showHotspotRow,
})(withRouter(ProductComment));
