import {forEach} from 'lodash';

export const getItemOverview = (companyData, statuses) =>{
    const data = {};
    //default statuses
    forEach(statuses, status => {
        data[status.name] = 0
    });


    forEach(companyData.projects, (project) => {
        forEach(project.orders, (order) => {
            forEach(order.items, item => {
                data[item.current_status] += 1
            })
        })
    })
    return data;
};
