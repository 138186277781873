import {
  SET_COMPANIES,
  UPDATE_COMPANY,
  SET_COMPANY_DETAIL,
  SET_ORDER,
  CREATE_ORDER,
  CREATE_PROJECT,
  CREATE_COMPANY,
  SET_ITEM,
  SET_STATUSES,
  SET_FILE_LOCKER,
  SET_ACTIVE_COMMENT_ID,
  SET_HOTSPOTS,
  SELECT_HOTSPOT,
} from "./companiesConstants";
import produce from "immer";

const INITIAL_STATE = {
  users: [],
  companies: [],
  companyDetail: {},
  itemStatuses: [],
  orderData: [],
  itemData: {},
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANIES:
      const { companies } = action;
      draft["companies"] = companies;
      break;

    case SET_STATUSES:
      const { statuses } = action;
      draft["itemStatuses"] = statuses;
      break;

    case UPDATE_COMPANY:
      const { companyId, companyData } = action;
      draft["companies"][companyId] = companyData;
      break;

    case SET_COMPANY_DETAIL:
      const { companyDetail } = action;
      draft["companyDetail"] = companyDetail;
      break;

    case SET_ORDER:
      const { orderData } = action;
      draft["orderData"] = orderData;
      break;

    case SET_ITEM:
      const { itemData } = action;
      draft["itemData"] = itemData;
      break;

    case CREATE_ORDER:
      const { newOrderData } = action;
      const endProjects = [];

      draft.companyDetail.projects.forEach((project) => {
        if (project.id != newOrderData.project_id) {
          endProjects.push(project);
        } else {
          project.orders.push(newOrderData);
          endProjects.push(project);
        }
      });
      draft.companyDetail.projects = endProjects;
      break;

    case CREATE_PROJECT:
      const { newProjectData } = action;
      draft.companyDetail.projects.push(newProjectData);
      break;

    case CREATE_COMPANY:
      const { newCompanyData } = action;
      draft.companies[newCompanyData.id] = newCompanyData;
      break;

    case SET_FILE_LOCKER:
      const { fileLockerData } = action;
      draft["fileLockerData"] = fileLockerData;
      break;

    case SET_ACTIVE_COMMENT_ID:
      const { activeCommentId } = action;
      draft["activeCommentId"] = activeCommentId;
      break;

    case SET_HOTSPOTS:
      const { allHotSpots } = action;
      draft["allHotSpots"] = allHotSpots;
      break;

    case SELECT_HOTSPOT:
      const { selectHotSpot } = action;
      draft["selectHotSpot"] = selectHotSpot;
      break;

    default:
      break;
  }

  return draft;
});
