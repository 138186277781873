export const isCreateOrderLoading = (isCreateOrderLoading) => {
  return {
    type: "IS_CREATE_ORDER_LOADING",
    isCreateOrderLoading,
  };
};

export const openCreateOrderModal = (isCreateOrderModalOpen) => {
  return {
    type: "OPEN_CREATE_ORDER_MODAL",
    isCreateOrderModalOpen,
  };
};


export const updateSelctedOrder = (selectedOrder) => {
  return {
    type: "UPDATE_SELECTED_ORDER",
    selectedOrder,
  };
}
