export const BACKEND_URL =
  window.location.hostname === "localhost" ||
  (window.location.hostname === "portal-beta.bitreel.com" &&
    window.location.protocol === "http:")
    ? "http://localhost:5000/api"
    : window.location.hostname === "cms-dev.bitreel.com" ||
      (window.location.hostname === "portal-beta.bitreel.com" &&
        window.location.protocol === "https:")
    ? "https://products-api-beta.bitreel.com/api"
    : "https://api.bitreel.com/api";

export const HOME_URL = "/products";
export const MODEL_SELECT_URL = "/products/:cid";
export const MODEL_INFO_URL = "/products/:cid/:pid";
export const QR_VIEW_URL = "/public/products/view_qr";

export const NO_AUTH_URLS = [QR_VIEW_URL];

export const LOGIN_ENDPOINT = `${BACKEND_URL}/oauth`;

export const LOGIN_URL = "/login";

export const LOADED_HOTSPOT = "hotspot-load";
export const NEW_HOTSPOT = "hotspot-new";
