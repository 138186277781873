import * as React from "react"

function DetailsSvg(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="#000"
                d="M.029 4.75H14.97v1.5H.029zM.029.75H14.97v1.5H.029zM.029 8.75H14.97v1.5H.029zM.029 12.75h8.853v1.5H.029z"
            />
        </svg>
    )
}

export default DetailsSvg
