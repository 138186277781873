import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Layout, { Content } from "antd/lib/layout/layout";
import CreateProductModal from "./containers/CreateProductModal";
import ProductDetailWithTNModal from "./containers/productDetailWithTNModal";
import CreateOrderModal from "./containers/CreateOrderModal";
import CreateProjectModal from "./containers/CreateProjectModal";
import ProductTable from "./containers/ProductTable";
import SiderMenu from "./containers/SiderMenu";
import { getCompanyRequest } from "../redux/persist/companiesActions";

import "./../styles/productListView.less";

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.props.getCompanyRequest(
      this.props.auth().token,
      this.props.match.params.cid
    );
  }

  render() {
    const { auth } = this.props;
    return (
      <>
        <SiderMenu />
        <Layout>
          <Content className="contentRightSide">
            <ProductTable auth={auth} />
            <CreateProductModal auth={auth} />
            <ProductDetailWithTNModal auth={auth} />
            <CreateOrderModal auth={auth} />
            <CreateProjectModal auth={auth} />
          </Content>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    companyDetail: state.companiesData.companyDetail,
  };
};

export default connect(mapStateToProps, {
  getCompanyRequest,
})(withRouter(ProductsList));
