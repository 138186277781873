import axios from "axios";

class S3Service {
  postToS3 = async (url, formData) => {
    const response = await axios({
      method: "post",
      url: url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  };
}
export default new S3Service();
