import React, { Component, useRef } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import _ from "lodash";

import { SearchOutlined } from "@ant-design/icons";
import {
  Select,
  Table,
  Button,
  Typography,
  Input,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

import {
  openCreateCompanyModal,
  openCreateUserModal,
} from "../redux/products/adminActions";
import {
  addUserToCompany,
  removeUserFromCompany,
  setCompanyDetail,
} from "../redux/persist/companiesActions";

import CreateCompanyModal from "./container/createCompanyModal";
import CreateUserModal from "./container/createUserModal";

import "./admin.scss";

const { Title } = Typography;

class AdminContent extends Component {
  state = {
    showCompanyModal: false,
    showUserModal: false,
    dropDown: false,
  };
  companyFormRef = React.createRef();
  userFormRef = React.createRef();

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  renderUsers = (company, index) => {
    const { users } = this.props;
    const { Option } = Select;
    const options = [];
    const selectRef = useRef(null);

    if (users) {
      Object.values(users).forEach((user) => {
        options.push(
          <Option key={user.email} value={user.email}>
            {user.email}
          </Option>
        );
      });
    }

    return (
      <Select
        ref={selectRef}
        showAction={["focus"]}
        mode="multiple"
        style={{ width: "100%" }}
        placeholder="Select users"
        value={_.map(company.users, "email")}
        onSelect={(e) => this.handleUserAdd(e, company)}
        onDeselect={(e) => this.handleUserDelete(e, company)}
      >
        {options}
      </Select>
    );
  };

  handleUserAdd(e, company) {
    const { users, auth, addUserToCompany } = this.props;
    const targetUser = _.map(users, (data) => data).find((user) => {
      return user.email === e;
    });
    addUserToCompany(auth().token, targetUser.id, company.id);
  }

  handleUserDelete(e, company) {
    const { auth, removeUserFromCompany } = this.props;
    const targetUser = _.map(company.users, (data) => data).find((user) => {
      return user.email === e;
    });
    removeUserFromCompany(auth().token, targetUser.id, company.id);
  }

  onTableChange(pagination, filters, sorter, extra) {
    //console.log('params', pagination, filters, sorter, extra);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            className="gradient-blue-button"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            className="gradient-white-button"
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            className="linkBtn"
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Company Name",
        //defaultSortOrder: "ascend",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name) => `${name}`,
        width: "20%",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Users",
        key: "users",
        disabled: true,
        render: (text, record, index) => this.renderUsers(record, index),
      },
      {
        key: "id",
        disabled: true,
        width: "90px",
        render: (text, record) => {
          return (
            <Link
              to={{
                pathname: "/products/" + record.id,
              }}
            >
              Projects
            </Link>
          );
        },
      },
    ];

    const { companies } = this.props;
    const data = _.map(companies.companies, (obj, index) => {
      return {
        ...obj,
        key: index,
      };
    }).sort((a,b) => new Date(b.updated) - new Date(a.updated));

    return (
      <>
        <Title className="centerText" style={{ marginTop: 60 }} level={1}>
          All Companies
        </Title>
        <Button
          className="bitreel-button-primary"
          style={{ marginBottom: 25 }}
          onClick={() => this.props.openCreateCompanyModal(true)}
        >
          CREATE COMPANY
        </Button>
        <Button
          className="bitreel-button-primary"
          style={{ marginBottom: 15, marginLeft: 15 }}
          onClick={() => this.props.openCreateUserModal(true)}
        >
          CREATE NEW USER
        </Button>
        <Content className="tableContent">
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data}
            onChange={this.onTableChange}
          />
        </Content>
        
        <CreateCompanyModal auth={this.props.auth} />
        <CreateUserModal auth={this.props.auth} />
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users.users,
    companies: state.companiesData,
  };
};

export default connect(mapStateToProps, {
  removeUserFromCompany,
  openCreateCompanyModal,
  openCreateUserModal,
  addUserToCompany,
  setCompanyDetail,
})(withRouter(AdminContent));
