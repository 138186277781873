import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, Upload, Form, notification, Row } from "antd";
import Icon from "@ant-design/icons";
import { connect } from "react-redux";
import {
  uploadFileLocker,
  getFileLockerRequest,
} from "../../../redux/persist/companiesActions";
import "@google/model-viewer";
import { Content } from "antd/lib/layout/layout";
import cloudUploadSVG from "./../../../svg/cloudUploadSvg";

class FileDragger extends Component {
  state = {
    fileList: [],
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  emptyFileList = () => {
    this.setState({ fileList: [] });
  };

  render() {
    const { name, message } = this.props;
    const { fileList } = this.state;

    this.name = name;

    return (
      <Form
        name={`${name}_file_locker`}
        onFinish={this.onLockerFormFinish}
        onFinishFailed={this.onLockerFormFailed}
        ref={this.lockerFormRef}
      >
        <Form.Item
          name={name}
          rules={[
            {
              required: false,
              message: { message },
            },
          ]}
          valuePropName="file"
        >
          <Upload.Dragger
            style={{ backgroundColor: "white", padding: 20 }}
            name="file"
            multiple={true}
            fileList={fileList}
            onChange={this.handleChange}
            beforeUpload={(file) => {
              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <Icon className="iconFileDragger" component={cloudUploadSVG} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Row>
          <Content className="alignToRight">
            <Button
              className="bitreel-button big-button"
              onClick={() => {
                this.emptyFileList();
              }}
            >
              Cancel
            </Button>
            <Button
              className="bitreel-button-primary big-button"
              onClick={() => {
                if (fileList.length > 0) {
                  this.onLockerFormFinish();
                  this.emptyFileList();
                }
              }}
            >
              Submit
            </Button>
          </Content>
        </Row>
      </Form>
    );
  }

  lockerFormRef = React.createRef();

  onLockerFormFailed = () => {
    console.log("Bad input!");
  };

  onLockerFormFinish = () => {
    const {
      auth,
      uploadFileLocker,
      companies,
      getFileLockerRequest,
    } = this.props;

    const { itemData } = companies;
    const data = this.lockerFormRef.current.getFieldsValue();

    let dataSources = [];
    if (data[this.name]) {
      dataSources = data[this.name].fileList;
    }

    if (dataSources.length) {
      this.setState({ loading: true }, () => {
        let promises = [];

        dataSources.forEach((dataSource) => {
          promises.push(
            uploadFileLocker(
              auth().token,
              itemData.id,
              dataSource.originFileObj,
              this.name,
              dataSource.name
            )
          );
        });

        Promise.all(promises).then(() => {
          getFileLockerRequest(auth().token, itemData.id).then(() => {
            this.setState({ loading: false });
          });
        });
      });
    } else {
      notification.error({
        message: "Bad Input",
        description: "Must include either a product image or reference file!",
      });
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
  };
};

export default connect(mapStateToProps, {
  uploadFileLocker,
  getFileLockerRequest,
})(withRouter(FileDragger));
