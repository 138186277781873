import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Modal, Form, Input } from "antd";

import { createCompany } from "../../redux/persist/companiesActions";
import { openCreateCompanyModal } from "../../redux/products/adminActions";

import "./../createCompany.scss";

class CreateCompanyModal extends Component {
  companyFormRef = React.createRef();

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  onCompanyFinish = async () => {
    const { createCompany, auth, openCreateCompanyModal } = this.props;
    const data = await this.companyFormRef.current.validateFields();
    createCompany(auth().token, data);
    openCreateCompanyModal(false);
  };

  render() {
    const { isOpen, openCreateCompanyModal } = this.props;

    return (
      <>
        <Modal
          title={"Create Company"}
          visible={isOpen}
          onOk={this.onCompanyFinish}
          onCancel={() => openCreateCompanyModal(false)}
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          okButtonProps={{
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            className: "bitreel-button",
          }}
        >
          <Form
            layout={"vertical"}
            name="company"
            onFinish={this.onCompanyFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.companyFormRef}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input company name - must be unique!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.admin.openCreateCompanyModal,
  };
};

export default connect(mapStateToProps, {
  createCompany,
  openCreateCompanyModal,
})(withRouter(CreateCompanyModal));
