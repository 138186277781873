import React from "react";

import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Helmet } from "react-helmet";
import favicon from "../../assets/favicon.png";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";

import MainAppContainer from "./MainAppContainer";
import "./root.component.scss";
import reducer from "../redux/reducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["product", "orders", "projects"], //do not save product state in the persist store
};

const history = createBrowserHistory();
const createMiddleware = applyMiddleware(thunk)(createStore);
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createMiddleware(persistedReducer);
const persistor = persistStore(store);
import "./root.component.scss";

export default function Root(props) {
  return (
    <section>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter history={history}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Products - Bitreel Portal</title>
              <link rel="shortcut icon" id="favicon" href={favicon}></link>
            </Helmet>
            <MainAppContainer />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </section>
  );
}
