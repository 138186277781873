import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import { uniqBy, map, some, forEach } from "lodash";
import { getItemRequest } from "../redux/persist/companiesActions";
import "./commentTable.less";
import {
  Button,
  ConfigProvider,
  Input,
  Space,
  Table,
  Typography,
  Modal,
  Empty,
} from "antd";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import {
  addAnnotation,
  onModelAnnotationClickRemove,
} from "../utils/modelViewerUtil";
import {
  addHotSpot,
  removeHotSpot,
  selectHotSpot,
  showHotspotRow,
} from "../redux/products/productActions";
import { LOADED_HOTSPOT } from "../constants/constants";
import messageImg from "../../assets/messageImg.png";

const { confirm } = Modal;

const { Text, Paragraph } = Typography;

let showAddMoreButton = true;

class CommentTable extends Component {
  constructor(props) {
    super(props);

    this.getColumns = this.getColumns.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
    this.showHotSpot = this.showHotSpot.bind(this);
    this.confirmShowHotSpot = this.confirmShowHotSpot.bind(this);
    this.hideHotSpot = this.hideHotSpot.bind(this);
    this.loadMoreComments = this.loadMoreComments.bind(this);
    this.showTotal = this.showTotal.bind(this);
  }

  state = {
    searchText: "",
    searchedColumn: "",
    showedHotspotRow: null,
    totalShownComment: 5,
    hideButton: false,
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  confirmShowHotSpot(row) {
    //remove previous hotspots
    this.hideHotSpot();

    //add new hotspots
    forEach(row.annotations, (annotation, index) => {
      const domHotspot = addAnnotation(
        document.getElementById("threeDModel"),
        annotation,
        index,
        LOADED_HOTSPOT
      );
      this.props.addHotSpot(domHotspot);
    });
    this.props.showHotspotRow(row.id);
  }

  showHotSpot(row) {
    if (
      some(this.props.allHotSpots, (obj) =>
        obj.classList.contains("hotspot-new")
      )
    ) {
      confirm({
        title:
          "You have unsaved hotpots, do you want to remove them and continue?",
        onOk: () => this.confirmShowHotSpot(row),
        onCancel: () => null,
        okButtonProps: { className: "bitreel-button-primary" },
        cancelButtonProps: { className: "bitreel-button" },
      });
    } else {
      this.confirmShowHotSpot(row);
    }
  }
  hideHotSpot() {
    //remove previous hotspots
    forEach(this.props.allHotSpots, (hotspot) => {
      onModelAnnotationClickRemove(hotspot);
      this.props.removeHotSpot(hotspot);
      this.props.selectHotSpot(null);
    });
    this.props.showHotspotRow(null);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            className="bitreel-button-primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            className="bitreel-button"
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text, row, index) => {
      return (
        <Paragraph style={{ width: "45vw" }}>
          {this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ) : (
            <Text>{text}</Text>
          )}
          {row.annotations.length > 0 ? (
            this.props.showedHotspotRow === row.id ? (
              <a onClick={() => this.hideHotSpot(row)}> Hide Hotspot</a>
            ) : (
              <a onClick={() => this.showHotSpot(row)}> Show Hotspot</a>
            )
          ) : null}
        </Paragraph>
      );
    },
  });

  getColumns() {
    return [
      {
        title: `All Comments (${this.props.itemNotes.length})`,
        dataIndex: "note",
        key: "note",
        width: "50%",
        ...this.getColumnSearchProps("note"),
      },
      {
        title: "Commentor",
        dataIndex: "user",
        key: "user",
        width: "25%",
        filters: uniqBy(
          map(this.props.itemNotes, (note) => {
            return { text: note.user, value: note.user };
          }),
          "value"
        ),
        onFilter: (value, data) => {
          return data.user === value;
        },
      },
      {
        title: "Version",
        dataIndex: "version_number",
        key: "version_number",
        width: "10%",
        filters: uniqBy(
          map(this.props.itemNotes, (note) => {
            return { text: note.version_number, value: note.version_number };
          }),
          "value"
        ),
        onFilter: (value, data) => {
          return data.version_number === value;
        },
      },
      {
        title: "Date",
        dataIndex: "created",
        key: "created",
        width: "15%",
        showSorterTooltip: false,
        sorter: {
          compare: (a, b) => Date.parse(a.created) - Date.parse(b.created),
          multiple: 1,
        },
      },
    ];
  }

  loadMoreComments() {
    if (showAddMoreButton) {
      this.setState({
        ...this.state,
        totalShownComment:
          this.state.totalShownComment + 5 < this.props.itemNotes.length
            ? this.state.totalShownComment + 5
            : this.props.itemNotes.length,
      });
    } else {
      this.setState({ ...this.state, hideButton: true });
    }
  }
  showTotal(total, range) {
    showAddMoreButton = total !== range[1];
  }

  render() {
    return (
      <>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              imageStyle={{
                height: 40,
              }}
              image={messageImg}
              description={"There are no submitted comments for this item."}
            />
          )}
        >
          <Table
            className="commentTable"
            loading={this.props.isLoading}
            style={{ marginTop: 8, width: "100%" }}
            dataSource={map(this.props.itemNotes, (note) => {
              return {
                ...note,
                key: note.id,
                version_number: note.version_number,
              };
            })}
            columns={this.getColumns()}
            onChange={() => {
              showAddMoreButton = true;
              this.setState({ ...this.state, hideButton: false });
            }} //reset add more button
            pagination={{
              current: 1,
              pageSize: this.state.totalShownComment,
              showTotal: (total, range) => this.showTotal(total, range),
            }}
            footer={(currentPageData) => {
              !currentPageData.length ? (showAddMoreButton = false) : null;
            }} //no content hide button
          />
        </ConfigProvider>
        {this.state.totalShownComment < this.props.itemNotes.length &&
        !this.state.hideButton ? (
          <Button
            className="load_more_button bitreel-button"
            onClick={() => this.loadMoreComments()}
          >
            LOAD MORE COMMENTS
          </Button>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showedHotspotRow: state.product.showedHotspotRow,
    isLoading: state.product.isFetchingItemLoading,
    itemNotes: state.companiesData.itemData.notes
      ? state.companiesData.itemData.notes
      : [],
    allHotSpots: state.product.allHotSpots,
  };
};

export default connect(mapStateToProps, {
  getItemRequest,
  removeHotSpot,
  selectHotSpot,
  addHotSpot,
  showHotspotRow,
})(withRouter(CommentTable));
