export const openCreateUserModal = (isOpen) => {
  return {
    type: "OPEN_CREATE_USER_MODEL",
    isOpen,
  };
};

export const openCreateCompanyModal = (isOpen) => {
  return {
    type: "OPEN_CREATE_COMPANY_MODEL",
    isOpen,
  };
};
