import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Menu,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { connect } from "react-redux";
const { Title, Text } = Typography;
import envFile from "../../assets/studio7Low.hdr";
import "@google/model-viewer";
import panCamera, {
  enterFullscreen,
  errorLoadListener,
  exitFullScreen,
  fullScreenListener,
} from "../utils/modelViewerUtil";
import Icon from "@ant-design/icons";

const QRCode = require("qrcode.react");

import {
  openProductDetailModal,
  openUpdateNewModal,
  updateEmptyModel,
} from "../redux/products/productActions";
import NewModelUploader from "../admin/newModelUploader";
import { QR_VIEW_URL } from "./../constants/constants";

import "./productPanel.less";
import CloudUploadOutlined from "@ant-design/icons/lib/icons/CloudUploadOutlined";
import DetailsSvg from "../svg/detailsSvg";
import FullscreenOutlined from "@ant-design/icons/lib/icons/FullscreenOutlined";
import FullscreenExitOutlined from "@ant-design/icons/lib/icons/FullscreenExitOutlined";
import threedModel from "../../assets/threedModel.png";
import ProductDetailsModal from "./productDetailsModal";

class ProductPanel extends Component {
  constructor(props) {
    super(props);
    this.threedmodelRef = React.createRef();
    this.escFunction = this.escFunction.bind(this);
    this.showEmptyModelSVG = this.showEmptyModelSVG.bind(this);
  }
  state = {
    fullscreen: false,
  };

  downloadMenu = (
    <Menu>
      <Menu.Item>
        <a
          href={
            this.props.companies.itemData.get_url
              ? this.props.companies.itemData.get_url.glb
              : ""
          }
          target="_blank"
        >
          {this.props.companies.itemData.name + ".glb"}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          href={
            this.props.companies.itemData.get_url
              ? this.props.companies.itemData.get_url.usdz
              : ""
          }
          target="_blank"
        >
          {this.props.companies.itemData.name + ".usdz"}
        </a>
      </Menu.Item>
    </Menu>
  );

  escFunction() {
    this.setState({ fullscreen: !this.state.fullscreen });
  }
  showEmptyModelSVG() {
    this.props.updateEmptyModel(true);
  }
  componentDidMount() {
    panCamera();
    fullScreenListener("add", this.escFunction);
    errorLoadListener("add", this.showEmptyModelSVG);
  }

  componentDidUpdate() {
    if (!this.props.isModelEmpty && !this.props.isProductLoading) {
      panCamera();
      fullScreenListener("add", this.escFunction);
    }
  }

  componentWillUnmount() {
    this.props.updateEmptyModel(false);
    fullScreenListener("remove", this.escFunction);
  }
  render() {
    return (
      <div className="product_panel_main">
        <Spin spinning={this.props.isProductLoading}>
          <Row>
            <span className="leftSide">
              <Space direction="vertical" style={{width:"100%"}}>
                <Row>
                  <Title level={3}>{this.props.companies.itemData.name}</Title>
                </Row>
                <Row>
                  <Title level={5}>
                    {"Status: " + this.props.companies.itemData.current_status}
                  </Title>
                </Row>
                <Row>
                  <Title level={5}>
                    {"Version Number: " +
                      this.props.companies.itemData.current_version}
                  </Title>
                </Row>
                <Row>
                   <Title level={5}>
                    {this.props.user && this.props.user.admin && this.props.companies.itemData.current_version_id ? "Version ID: " +
                        this.props.companies.itemData.current_version_id : null}
                   </Title>
                </Row>
                <Row>
                  <Title level={5}>
                    {this.props.user && this.props.user.admin && this.props.companies.itemData.order.project.company_id ? "Company ID: " +
                          this.props.companies.itemData.order.project.company_id : null}
                  </Title>
                </Row>
                <Row>
                  <Title level={5}>
                    {this.props.user && this.props.user.admin && this.props.companies.itemData.order.project_id? "Project ID: " +
                        this.props.companies.itemData.order.project_id : null}
                  </Title>
                </Row>
                <Row>
                  <Title level={5}>
                    {this.props.user && this.props.user.admin && this.props.companies.itemData.order_id? "Order ID: " +
                        this.props.companies.itemData.order_id : null}
                  </Title>
                </Row>
                <Row>
                  {this.props.user && this.props.user.admin ? (
                    <Button
                      icon={<CloudUploadOutlined />}
                      className="bitreel-button"
                      style={{ width: "100%" }}
                      onClick={() => this.props.openUpdateNewModal(true)}
                    >
                      UPLOAD NEW VERSION
                    </Button>
                  ) : null}
                </Row>
                <Row>
                  <Button
                    icon={<Icon component={DetailsSvg} />}
                    className="bitreel-button"
                    style={{ width: "100%" }}
                    onClick={() => this.props.openProductDetailModal(true)}
                  >
                    VIEW/EDIT DETAILS
                  </Button>
                </Row>
              </Space>
            </span>
            <span className="middleSide">
              <div
                className="model-viewer-dev"
                style={{
                  height: "70vh",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
              >
                {this.props.isModelEmpty ? (
                  <Row style={{height: "100%"}} justify={"center"} align={"middle"}>
                    <Empty
                      className="imageMiddle"
                      style={{width: "100%", height: "100%", margin: 0}}
                      imageStyle={{
                        height: "100%",
                        width: "100%",
                        margin: 0
                      }}
                      image={threedModel}
                      description={false}
                    />
                  </Row>
                ) : (
                  <model-viewer
                    onContextMenu={(e) => e.preventDefault()}
                    id="threeDModel"
                    camera-orbit="-20deg 60deg 140%"
                    max-camera-orbit="auto auto 200%"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={
                      !this.props.isProductLoading &&
                      !!this.props.companies.itemData.get_url
                        ? this.props.companies.itemData.get_url.glb
                        : null
                    }
                    alt="A 3D model"
                    auto-rotate
                    camera-controls
                    autoplay
                    data-js-focus-visible
                    environment-image={envFile}
                    ref={this.threedmodelRef}
                  >
                    {this.state.fullscreen ? (
                      <Button
                        icon={<FullscreenExitOutlined />}
                        className="bitreel-button exit-full-screen"
                        onClick={() => {
                          exitFullScreen();
                        }}
                      >
                        Exit
                      </Button>
                    ) : null}
                  </model-viewer>
                )}
              </div>
            </span>
            <span className="rightSide">
              <Space direction={"vertical"}>
                 {this.props.isModelEmpty ? null : <Row align={"center"}>
                  <Button
                    icon={<FullscreenOutlined />}
                    disabled={this.props.isModelEmpty}
                    className="bitreel-button"
                    style={{ width: "100%" }}
                    onClick={() => {
                      enterFullscreen();
                    }}
                  >
                    VIEW FULL SCREEN
                  </Button>
                </Row>}
                <Row align={"center"}>
                  {this.props.user && this.props.user.admin ? (
                    <Dropdown
                      disabled={this.props.isModelEmpty}
                      overlay={this.downloadMenu}
                    >
                      <Button
                        style={{ width: "100%" }}
                        className="bitreel-button"
                      >
                        DOWNLOAD MODEL
                      </Button>
                    </Dropdown>
                  ) : null}
                </Row>
                <Row align={"center"}>
                  {this.props.companies.itemData.get_url &&
                  !this.props.isModelEmpty ? (
                    <QRCode
                      className="qrCode"
                      value={`${
                        window.location.origin
                      }${QR_VIEW_URL}?usdzModel=${encodeURIComponent(
                        this.props.companies.itemData.get_url.usdz
                      )}&glbModel=${encodeURIComponent(
                        this.props.companies.itemData.get_url.glb
                      )}&showAR=true`}
                    />
                  ) : null}
                </Row>
                {this.props.isModelEmpty ? null :                 <Row align={"center"}>
                  <Text>
                    View in your space
                  </Text>
                </Row>}
              </Space>
            </span>
          </Row>
        </Spin>
        <NewModelUploader auth={this.props.auth} />
        <ProductDetailsModal auth={this.props.auth} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isModelEmpty: state.product.isModelEmpty,
    companies: state.companiesData,
    isProductLoading:
      !!state.product.isUpdateLoading ||
      !!state.product.isSubmitNotesLoading ||
      !!state.product.isSubmitVersionLoading ||
      !!state.product.isFetchingItemLoading,
    cursorStyle: state.product.cursorStyle,
  };
};

export default connect(mapStateToProps, {
  openUpdateNewModal,
  openProductDetailModal,
  updateEmptyModel,
})(withRouter(ProductPanel));
