//determine ipad pro
export function isIpadOS() {
    return (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
    );
}

//Determine OS
export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if ((/iPad|iPhone|iPod/.test(userAgent) || isIpadOS()) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

//determine whether to invoke AR
export function launchIOSQuickLookAR(usdzSrc) {
    const anchor = document.createElement("a");
    anchor.setAttribute("rel", "ar");
    anchor.appendChild(document.createElement("img"));
    anchor.setAttribute("href", usdzSrc);
    anchor.click();
}
export function launchAndroidAR(glb) {
    const glbHref = `intent://arvr.google.com/scene-viewer/1.0?file=${glb}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
    const anchor = document.createElement("a");
    anchor.setAttribute("href", glbHref);
    anchor.click();
}
