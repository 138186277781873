export const isCreateProjectLoading = (isCreateProjectLoading) => {
  return {
    type: "IS_CREATE_PROJECT_LOADING",
    isCreateProjectLoading,
  };
};

export const setOpenKeys = (openedProjects) => {
  return {
    type: "OPEN_PROJECTS",
    openedProjects,
  }
}

export const openCreateProjectModal = (isCreateProjectModalOpen) => {
  return {
    type: "OPEN_CREATE_PROJECT_MODAL",
    isCreateProjectModalOpen,
  };
};
