import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import { Image, Button, Table, Space, Input, Spin, Row, Card } from "antd";
import { Content } from "antd/lib/layout/layout";
import Icon, { SearchOutlined } from "@ant-design/icons";
import ChairSvg from "./../../svg/chairSvg";
import _ from "lodash";
import moment from "moment";

import { BACKEND_URL, MODEL_INFO_URL } from "../../constants/constants";

import noImageSvg from "./../../svg/noImageSvg";

import {
  setOrder,
  getFileLockerRequest,
  setItem,
} from "../../redux/persist/companiesActions";
import {
  isCreateProductModalOpen,
  openEditProductModal,
} from "../../redux/products/productActions";

import Title from "antd/lib/typography/Title";

class ProductTable extends Component {
  state = {
    showEditModal: false,
    searchText: "",
    searchedColumn: "",
  };
  itemFormRef = React.createRef();

  componentWillUnmount() {
    this.props.setOrder(null);
  }

  //history
  render() {
    const {
      companies,
      auth,
      history,
      getFileLockerRequest,
      isLoading,
    } = this.props;

    const { companyDetail, orderData, itemStatuses } = companies;
    const { name } = companyDetail;

    const columns = [
      {
        dataIndex: "thumbnail",
        key: "thumbnail",
        render: (text, record) => {
          return this.renderThumbnail(record);
        },
      },
      {
        title: "Model Name",
        ...this.getColumnSearchProps("name"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Status",
        dataIndex: "current_status",
        key: "current_status",
        filters: _.map(Object.values(itemStatuses), (obj) => {
          return {
            text: obj.name,
            value: obj.name,
          };
        }),
        onFilter: (value, record) => {
          return record.current_status === value;
        },
      },
      {
        title: "Updated",
        dataIndex: "updated",
        key: "updated",
        render: (text, record) => {
          return `${moment(text).format("YYYY-MM-DD HH:mm:ss")} GMT`;
        },
        sorter: (a, b) => {
          return moment(a.updated) - moment(b.updated);
        },
      },
      {
        title: "",
        key: "id",
        width: "100px",
        render: (text, record) => {
          return (
            <Link to={"/products/" + this.props.match.params.cid + "/" + record.id}>
               <Button
                className="bitreel-button"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                MANAGE
              </Button>
            </Link>

          );
        },
      },
    ];

    const companyName = name;
    const orderName = orderData ? orderData.name : "No Orders";
    const projectName = orderData ? orderData.project_name : "No Projects";

    return (
      <Content style={{ marginTop: 60 }} className="productListViewTable">
        <Card className="headerLeftContent">
          <Title level={1} className="headerLeftContentTitle">
            {companyName}
          </Title>
          {this.showProjectNameOrderNameTitle(
            orderData,
            projectName,
            orderName
          )}
        </Card>

        {this.showCreateItemButtonHeader(orderData)}

        <Spin spinning={isLoading}>{this.showTable(orderData, columns)}</Spin>
      </Content>
    );
  }

  renderThumbnail(record) {
    const { auth, match, history, getFileLockerRequest } = this.props;
    if (record.thumbnail_url) {
      return (
        <Link to={"/products/" + match.params.cid + "/" + record.id}>
          <Button
            style={{
              border: "none",
              padding: 0,
              margin: 0,
              width: "auto",
              height: "auto",
              maxHeight: 150,
            }}
            onClick={(e) => {
              e.stopPropagation();
              getFileLockerRequest(auth().token, record.id);
            }}
          >
            <Image
              preview={false}
              width={120}
              src={`${BACKEND_URL}/${record.thumbnail_url}`}
            />
          </Button>
        </Link>
      );
    } else {
      return <Icon className="noImageSvg" component={noImageSvg} />;
    }
  }

  isOrderEmpty(orderData) {
    return !_.has(orderData, "items") || orderData.items.length === 0;
  }

  showTable(orderData, columns) {
    const { openEditProductModal, setItem } = this.props;
    if (this.isOrderEmpty(orderData)) {
      return (
        <Card className="tableContent emptyTable">
          <Content className="itemsInsideEmptyTable">
            <Icon className="chairIcon" component={ChairSvg} />
            <Title style={{ marginBottom: 20 }} level={5}>
              There are no 3D models in your render queue.
            </Title>
            <Button
              className="bitreel-button-primary"
              style={{ marginBottom: 15 }}
              disabled={!Boolean(orderData)}
              onClick={() => {
                setItem({});
                this.props.isCreateProductModalOpen(true);
              }}
            >
              CREATE A NEW MODEL
            </Button>
          </Content>
        </Card>
      );
    } else {
      return (
        <Content className="tableContent">
          <Table
            dataSource={_.map(orderData ? orderData.items : {}, (data) => data)}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setItem(record);
                  openEditProductModal(true);
                }, // click row
                onContextMenu: (event) => {}, // right button click row
              };
            }}
          />
        </Content>

      );
    }
  }

  showProjectNameOrderNameTitle(orderData, projectName, orderName) {
    if (this.isOrderEmpty(orderData)) {
      return <></>;
    } else {
      return (
        <Title level={4} className="headerLeftContentTitle">
          {`${projectName}: ${orderName}`}
        </Title>
      );
    }
  }

  showCreateItemButtonHeader(orderData) {
    const { setItem } = this.props;
    if (this.isOrderEmpty(orderData)) {
      return <></>;
    } else {
      return (
        <Button
          className="bitreel-button-primary"
          style={{ marginBottom: 15 }}
          disabled={!Boolean(orderData)}
          onClick={() => {
            setItem({});
            this.props.isCreateProductModalOpen(true);
          }}
        >
          CREATE NEW MODEL
        </Button>
      );
    }
  }

  mouseDownHandler = (event, record) => {
    if (event.button === 1 || event.button === 2) {
      const { setItem, auth, getFileLockerRequest } = this.props;
      setItem(record);
      getFileLockerRequest(auth().token, record.id).then(
        window.open(MODEL_INFO_URL, "_blank")
      );
    }
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            className="bitreel-button"
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
    isLoading: state.companies.isFetchingCompanyInfo,
  };
};

export default connect(mapStateToProps, {
  setOrder,
  setItem,
  getFileLockerRequest,
  openEditProductModal,
  isCreateProductModalOpen,
})(withRouter(ProductTable));
