import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Modal, Form, Input } from "antd";

import { createUser } from "../../redux/users/usersActions";
import { openCreateUserModal } from "../../redux/products/adminActions";

import "./../createUser.scss";

class CreateUserModal extends Component {
  userFormRef = React.createRef();

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  onUserFinish = async () => {
    const { createUser, auth, openCreateUserModal } = this.props;
    const data = await this.userFormRef.current.validateFields();
    createUser(auth().token, data);
    openCreateUserModal(false);
  };

  render() {
    return (
      <>
        <Modal
          title={"Create User"}
          visible={this.props.isOpen}
          onOk={this.onUserFinish}
          okButtonProps={{
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            className: "bitreel-button",
          }}
          onCancel={() => this.props.openCreateUserModal(false)}
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
        >
          <Form
            layout={"vertical"}
            name="user"
            onFinish={this.onUserFinish}
            onFinishFailed={this.onFinishFailed}
            ref={this.userFormRef}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input user email - must be unique!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.admin.openCreateUserModal,
  };
};

export default connect(mapStateToProps, {
  createUser,
  openCreateUserModal,
})(withRouter(CreateUserModal));
