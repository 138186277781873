import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import "@google/model-viewer";
import _ from "lodash";


import { Content } from "antd/lib/layout/layout";
import { Input, Modal, Typography, Upload, Spin } from "antd";

import {
  openUpdateNewModal,
  uploadNewFile,
  createItemNote,
  createItemVersion,
  updateItemVersion,
  updateEmptyModel,
} from "../redux/products/productActions";
import { getItemRequest } from "../redux/persist/companiesActions";


import CloudUploadSvg from "./../svg/cloudUploadSvg";
import "../productView/productPanel.less";

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;
const { TextArea } = Input;

class NewModelUploader extends Component {
  constructor(props) {
    super(props);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.onUploading = this.onUploading.bind(this);
    this.getStatusId = this.getStatusId.bind(this);
    this.submitNotes = this.submitNotes.bind(this);
  }

  state = {
    glb: null,
    noteContent: "",
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.isLoading !== this.props.isLoading &&
      this.props.isLoading === true
    ) {
      this.props.openUpdateNewModal(false);
    }
    return true;
  }

  beforeUpload(file) {
      this.setState({ ...this.state, glb: file });
      return false;
  }

  getStatusId(approvalText) {
    const { itemStatuses } = this.props;
    return _.filter(Object.values(itemStatuses), (obj) => {
      return obj["name"] === approvalText;
    })[0].id;
  }

  submitNotes(res) {
    //submit notes
    if (this.state.noteContent !== "") {
      this.props.createItemNote(this.props.auth().token, {
        note: this.state.noteContent,
        item_version_id: res.id,
      });
    }
  }

  onUploading() {
    const {
      post_url,
      current_status,
      id,
      current_version_id,
    } = this.props.itemData;
    const { glb } = post_url;
    const glbFormData = new FormData();
    Object.keys(glb.fields).forEach((key) => {
      glbFormData.append(key, glb.fields[key]);
    });
    glbFormData.append("file", this.state.glb);
    //call version API and notes API
    if (current_status !== "Created") {
      this.props
        .createItemVersion(this.props.auth().token, { item_id: id })
        .then((res) => this.submitNotes(res));
    } else {
      this.props.updateItemVersion(
        this.props.auth().token,
        current_version_id,
        {
          item_id: id,
          status_id: this.getStatusId("Client Review"),
        }
      );
    }

    //submit file
    this.props.uploadNewFile(glb.url, glbFormData).then(() => {
      this.props.getItemRequest(
        this.props.auth().token,
        this.props.itemData.id
      );
      this.props.updateEmptyModel(false);
    });
  }

  render() {
    return (
      <Modal
        title={"Update New Model"}
        visible={this.props.isOpen}
        onOk={() => this.onUploading()}
        onCancel={() => this.props.openUpdateNewModal(false)}
        destroyOnClose={true}
        cancelText="CANCEL"
        okText="SUBMIT"
        okButtonProps={{
          disabled: !this.state.glb || this.props.isLoading,
          className: "bitreel-button-primary",
        }}
        cancelButtonProps={{
          disabled: this.props.isLoading,
          className: "bitreel-button",
        }}
        closable={!this.props.isLoading}
        maskClosable={!this.props.isLoading}
      >
        <Spin spinning={this.props.isLoading}>
          <Paragraph>
            <Text strong>3D model</Text>
            <Text type="secondary">(Any guidance copy)</Text>
          </Paragraph>
          <Content className="bitreel-upload-dragger">
            <Dragger
              style={{ backgroundColor: "white" }}
              name="file"
              multiple={false}
              accept=".glb"
              beforeUpload={(file) => this.beforeUpload(file)}
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadSvg />
              </p>
              <p className="ant-upload-text">
                Click or drag thumbnail file to this area to upload
              </p>
            </Dragger>
          </Content>
          

          <Paragraph style={{ marginTop: 40 }}>
            <Text>Additional Comments</Text>
          </Paragraph>
          <TextArea
            rows={4}
            allowClear={true}
            value={this.state.noteContent}
            onChange={(text) =>
              this.setState({ ...this.state, noteContent: text.target.value })
            }
          />
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isOpen: state.product.openUploadNewModel,
    itemData: state.companiesData.itemData,
    itemStatuses: state.companiesData.itemStatuses,
    isLoading:
      !!state.product.isUpdateLoading ||
      !!state.product.isSubmitNotesLoading ||
      !!state.product.isSubmitVersionLoading,
  };
};

export default connect(mapStateToProps, {
  openUpdateNewModal,
  createItemVersion,
  uploadNewFile,
  createItemNote,
  updateItemVersion,
  getItemRequest,
  updateEmptyModel,
})(withRouter(NewModelUploader));
