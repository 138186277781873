import React, { Component } from "react";
import { withRouter } from "react-router";
import FileDragger from "../components/FileDragger";
import ReferenceList from "../components/ReferenceList";
import { connect } from "react-redux";
import "@google/model-viewer";
import { Content } from "antd/lib/layout/layout";

class ReferenceMaterialComponent extends Component {
  render() {
    const { label, name, message, companies, auth } = this.props;
    return (
      <Content>
        <FileDragger
          auth={auth}
          companies={companies}
          label={label}
          name={name}
          message={message}
        />
        <ReferenceList auth={auth} name={name} companies={companies} />
      </Content>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(ReferenceMaterialComponent));
