import produce from "immer";

const INITIAL_STATE = {
  openCreateUserModal: false,
  openCreateCompanyModal: false,
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_CREATE_USER_MODEL":
      draft.openCreateUserModal = action.isOpen;
      break;
    case "OPEN_CREATE_COMPANY_MODEL":
      draft.openCreateCompanyModal = action.isOpen;
      break;
    default:
      break;
  }

  return draft;
});
