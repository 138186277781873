import React, { Component } from "react";
import { withRouter } from "react-router";
import { Input, Form } from "antd";
import { connect } from "react-redux";
import "@google/model-viewer";

import "../productView/productDetails.less";

import { checkCurrency, checkURL } from "../utils/formUtil";

class ProductDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
          <Form layout={"vertical"} ref={this.props.formRef}>
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please type the product name!" },
              ]}
              initialValue={this.props.itemData.name}
            >
              <Input placeholder="Please type the product name!" />
            </Form.Item>
            <Form.Item
              label="Product Price"
              name="price"
              rules={[
                { required: true, message: "Please type the product price!" },
                { validator: (_, value) => checkCurrency(_, value) },
              ]}
              initialValue={this.props.itemData.price}
            >
              <Input placeholder="Please type the product price!" />
            </Form.Item>
            <Form.Item
              label="Product Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please type the product description!",
                },
              ]}
              initialValue={this.props.itemData.description}
            >
              <Input.TextArea rows={this.props.descriptionHeight ? this.props.descriptionHeight  : 8} />
            </Form.Item>
            <Form.Item
              label="Product URL"
              name="product_url"
              rules={[
                { required: false },
                { validator: (_, value) => checkURL(_, value) },
              ]}
              initialValue={this.props.itemData.product_url}
            >
              <Input.TextArea rows={this.props.urlHeight ? this.props.urlHeight  : 3}/>
            </Form.Item>
          </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
    itemData: state.companiesData.itemData,
  };
};

export default connect(mapStateToProps, {
})(withRouter(ProductDetails));
