import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  Row,
  Table,
  Typography,
} from "antd";
import { map, keys } from "lodash";
import { getItemOverview } from "../utils/dataUtils";
import { Link } from "react-router-dom";
const { Title, Text } = Typography;

import "./clientContent.less";

class ClientContent extends Component {
  constructor(props) {
    super(props);
    this.getColumns = this.getColumns.bind(this);
    this.convertData = this.convertData.bind(this);
  }

  getColumns() {
    return [
      {
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        width: "20%",
      },
      {
        title: "3d Model Summary",
        dataIndex: "itemOverview",
        key: "itemOverview",
        width: "80%",
        render: (text, record, index) => {
          return (
            <Row justify="space-between">
              {map(keys(record.itemOverview), (itemStatus, index) => {
                return (
                  <div key={index}>
                    <Col>
                      <Text key={index}>{itemStatus}: </Text>
                      <Text strong>{record.itemOverview[itemStatus]}</Text>
                    </Col>
                    
                  </div>
                );
              })}
              <Col key="button">
                <Link
                  to={{
                    pathname: "/products/" + record.id,
                  }}
                >
                  Projects
                </Link>
              </Col>
            </Row>
          );
        },
      },
    ];
  }

  convertData() {
    const filteredData = map(this.props.companies, (company) => {
      const itemOverview = getItemOverview(company, this.props.statuses);
      return { ...company, key: company.id, itemOverview: itemOverview };
    });
    return filteredData;
  }

  render() {
    return (
      <>
        <Row style={{ marginTop: 60 }}  align="middle" justify="center">
          <Title level={2}>Your Assigned Companies</Title>
        </Row>
        <Row align="middle" justify="center">
          <ConfigProvider
            renderEmpty={() => (
              <>
                <Row align="middle" justify="center">
                  <Title level={4}>
                    There is no company assigned to your profile
                  </Title>
                </Row>
                {/*<Row align="middle" justify="center">*/}
                {/*    <Button>Click</Button>*/}
                {/*</Row>*/}
              </>
            )}
          >
            <Layout.Content className="tableContent">
              <Table
                loading={this.props.isLoading}
                style={{ marginTop: 8, width: "100%" }}
                dataSource={this.convertData()}
                columns={this.getColumns()}
                id="companies-table"
              />
            </Layout.Content>
          </ConfigProvider>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    statuses: state.companiesData.itemStatuses,
    companies: state.companiesData.companies,
    isLoading: state.companies.isFetchingCompanies,
  };
};

export default connect(mapStateToProps, {
})(withRouter(ClientContent));
