export const isFetchingCompanies = (isFetchingCompanies) => {
  return {
    type: "IS_FETCHING_COMPANIES",
    isFetchingCompanies,
  };
};

export const isFetchingCompanyInfo = (isFetchingCompanyInfo) => {
  return {
    type: "IS_FETCHING_COMPANY_INFO",
    isFetchingCompanyInfo,
  };
};
