import React, { Component } from "react";
import { withRouter } from "react-router";
import { Form, List } from "antd";
import { connect } from "react-redux";
import ReferenceItem from "./ReferenceItem";
import "@google/model-viewer";

class ReferenceList extends Component {
  render() {
    const { name, companies, auth } = this.props;
    const { itemData, fileLockerData } = companies;
    let dataSource = [];

    if (fileLockerData) {
      dataSource = fileLockerData[name];
    }

    return (
      <Form name={`${name}_item`}>
        <List
          className="list"
          bordered={false}
          dataSource={dataSource}
          itemLayout="horizontal"
          renderItem={(item) => (
            <ReferenceItem
              auth={auth}
              itemUrl={item.url}
              itemFileName={item.file_name}
              name={name}
              itemDataId={itemData.id}
            />
          )}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
  };
};

export default connect(mapStateToProps, {})(withRouter(ReferenceList));
