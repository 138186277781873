import React, { Component } from "react";
import { withRouter } from "react-router";
import { Popconfirm, Button } from "antd";
import { connect } from "react-redux";
import { deleteFileLocker } from "../../../redux/persist/companiesActions";

import "@google/model-viewer";

class DeleteFileFromList extends Component {
  render() {
    const {
      auth,
      itemDataId,
      name,
      itemFileName,
      deleteFileLocker,
    } = this.props;

    return (
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => {
          deleteFileLocker(auth().token, itemDataId, name, itemFileName);
        }}
      >
        <Button className="removeButtonList" type="link">
          REMOVE
        </Button>
      </Popconfirm>
    );
  }
}

export default connect(null, {
  deleteFileLocker,
})(withRouter(DeleteFileFromList));
