import S3Service from "../../service/s3Service";
import ApiService from "../../service/apiService";
import { getCompanyRequest, getItemRequest } from "../persist/companiesActions";
import { SELECT_HOTSPOT } from "../persist/companiesConstants";

export const openUpdateNewModal = (isOpen) => {
  return {
    type: "OPEN_UPDATE_NEW_MODAL",
    isOpen,
  };
};

export const openProductDetailModal = (isOpen) => {
  return {
    type: "OPEN_PRODUCT_DETAIL_MODAL",
    isOpen,
  };
};

export const isUpdateLoading = (isUpdateLoading) => {
  return {
    type: "IS_UPDATE_LOADING",
    isUpdateLoading,
  };
};

export const openEditProductModal = (isEditProductModalOpen) => {
  return {
    type: "OPEN_EDIT_PRODUCT_MODAL",
    isEditProductModalOpen,
  };
};

export const isAnnotationLoading = (isAnnotationLoading) => {
  return {
    type: "IS_ANNOTATION_LOADING",
    isAnnotationLoading,
  };
};

export const uploadNewFile = (url, formData) => async (dispatch) => {
  try {
    dispatch(isUpdateLoading(true));
    const response = await S3Service.postToS3(url, formData);
    const { data } = response;
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isUpdateLoading(false));
  }
};

export const createItem = (token, itemData) => async (dispatch) => {
  try {
    dispatch(isCreateProductLoading(true));
    const response = await ApiService.postItem(token, itemData);
    const { data } = response;
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isCreateProductLoading(false));
  }
};

export const isCreateProductLoading = (isCreateProductLoading) => {
  return {
    type: "IS_CREATE_PRODUCT_LOADING",
    isCreateProductLoading,
  };
};

export const isCreateProductModalOpen = (isCreateProductModalOpen) => {
  return {
    type: "IS_CREATE_PRODUCT_MODAL_OPEN",
    isCreateProductModalOpen,
  };
};

export const isSubmitVersionLoading = (isSubmitVersionLoading) => {
  return {
    type: "IS_SUBMIT_VERSION_LOADING",
    isSubmitVersionLoading,
  };
};

export const isFetchingItemLoading = (isFetchingItemLoading) => {
  return {
    type: "IS_FETCHING_ITEM_LOADING",
    isFetchingItemLoading,
  };
};

export const isSubmitNotesLoading = (isSubmitNotesLoading) => {
  return {
    type: "IS_SUBMIT_NOTES_LOADING",
    isSubmitNotesLoading,
  };
};

export const createItemVersion = (token, itemVersionData) => async (
  dispatch
) => {
  try {
    dispatch(isSubmitVersionLoading(true));
    const response = await ApiService.postItemVersion(token, itemVersionData);
    const { data } = response;
    dispatch(getCompanyRequest(token, data.item.order.project.company_id));
    dispatch(getItemRequest(token, data.item.id));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isSubmitVersionLoading(false));
  }
};

export const updateItemVersion = (
  token,
  itemVersionId,
  itemVersionData
) => async (dispatch) => {
  try {
    dispatch(isSubmitVersionLoading(true));
    const response = await ApiService.patchItemVersion(
      token,
      itemVersionId,
      itemVersionData
    );
    const { data } = response;
    dispatch(getCompanyRequest(token, data.item.order.project.company_id));
    dispatch(getItemRequest(token, data.item.id));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isSubmitVersionLoading(false));
  }
};

export const createItemNote = (token, itemNoteData) => async (dispatch) => {
  try {
    dispatch(isSubmitNotesLoading(true));
    const response = await ApiService.postItemNote(token, itemNoteData);
    const { data } = response;
    dispatch(
      getCompanyRequest(token, data.item_version.item.order.project.company_id)
    );
    dispatch(getItemRequest(token, data.item_version.item.id));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isSubmitNotesLoading(false));
  }
};

export const editItem = (token, itemData, itemId) => async (dispatch) => {
  try {
    dispatch(isUpdateLoading(true));
    const response = await ApiService.patchItem(token, itemId, itemData);
    const { data } = response;
    dispatch(getItemRequest(token, data.id));
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isUpdateLoading(false));
  }
};

export const editThumbnailRequest = (
  token,
  thumbnailId,
  thumbnailData
) => async (dispatch) => {
  try {
    dispatch(isThumbnailLoading(true));
    const response = await ApiService.patchThumbnail(
      token,
      thumbnailId,
      thumbnailData
    );
    const { data } = response;
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isThumbnailLoading(false));
  }
};

export const createThumbnailRequest = (token, thumbnailData) => async (
  dispatch
) => {
  try {
    dispatch(isThumbnailLoading(true));
    const response = await ApiService.postThumbnail(token, thumbnailData);
    const { data } = response;
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isThumbnailLoading(false));
  }
};

export const isThumbnailLoading = (isThumbnailLoading) => {
  return {
    type: "IS_THUMBNAIL_LOADING",
    isThumbnailLoading,
  };
};

export const addHotSpot = (domHotSpot) => {
  return {
    type: "ADD_HOTSPOT",
    domHotSpot,
  };
};

export const showHotspotRow = (showedHotspotRow) => {
  return {
    type: "SHOW_HOTSPOT_ROW",
    showedHotspotRow,
  };
};

export const selectHotSpot = (selectHotSpot) => {
  return {
    type: "SELECT_HOTSPOT",
    selectHotSpot,
  };
};

export const updateEmptyModel = (isModelEmpty) => {
  return {
    type: "EMPTY_MODEL",
    isModelEmpty,
  };
};

export const removeHotSpot = (oldHotSpot) => {
  return {
    type: "REMOVE_HOTSPOT",
    oldHotSpot,
  };
};

export const createAnnotation = (token, annotationData) => async (dispatch) => {
  try {
    dispatch(isAnnotationLoading(true));
    const response = await ApiService.postAnnotation(token, annotationData);
    const { data } = response;
    return data;
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(isAnnotationLoading(false));
  }
};

// export const modelCursorStyle = (cursorStyle) => {
//   return {
//     type: "MODEL_CURSOR_STYLE",
//     cursorStyle
//   }
// }
