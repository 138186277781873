import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {Col, Modal, Row, Spin, Upload, Form, message} from "antd";
import ProductDetails from "../../productView/productDetails";
import {createThumbnailRequest, isCreateProductModalOpen} from "../../redux/products/productActions";
import {createItem} from "../../redux/products/productActions";
import {getCompanyRequest, getFileLockerRequest, uploadFileLocker} from "../../redux/persist/companiesActions";
import {cloneDeep, forEach} from 'lodash';
import CloudUploadSvg from "../../svg/cloudUploadSvg";

const {Dragger} = Upload;

class CreateProductModal extends Component {

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.uploadRef = React.createRef();
    this.submit = this.submit.bind(this);
    this.createProduct = this.createProduct.bind(this);
  }

  state={
    isLoading: false,
  }

  createProduct(uploadData, detailValues) {
    const itemData = cloneDeep(detailValues);
    itemData.order_id = this.props.orderData.id
    this.setState({isLoading: true})
    //create item first
    this.props.createItem(this.props.auth().token, itemData)
        .then((res) => {
          let promises = [];
          //upload thumbnail
          const reader = new FileReader();
          reader.onload = e => {
            const thumbnailData = e.target.result
            promises.push(this.props.createThumbnailRequest(this.props.auth().token, {
                item_id: res.id,
                thumbnail: thumbnailData
              }))
            }
          reader.readAsDataURL(uploadData.thumbnail.file);
          //upload images and files

          if (uploadData.images){
            forEach(uploadData.images.fileList, (image) => {
              promises.push(
                  this.props.uploadFileLocker(
                      this.props.auth().token,
                      res.id,
                      image.originFileObj,
                      "product_image",
                      image.name
                  )
              );
            });
          }

          if (uploadData.files){
            forEach(uploadData.files.fileList, (image) => {
              promises.push(
                  this.props.uploadFileLocker(
                      this.props.auth().token,
                      res.id,
                      image.originFileObj,
                      "reference_file",
                      image.name
                  )
              );
            });  
          }
          
          Promise.all(promises).then(() => {
            this.setState({isLoading: false});
            this.props.isCreateProductModalOpen(false);
            this.props.getCompanyRequest(this.props.auth().token, this.props.match.params.cid, this.props.openedProjects[0], this.props.selectedOrder[0]);
          });
        })
  }

  async submit() {
    try {
      const uploadData = this.uploadRef.current.getFieldsValue()
      let uploadError = false;
      let errorStr = '';

      if (!uploadData.thumbnail || uploadData.thumbnail.fileList.length === 0){
        errorStr += "Please add thumbnail. "
        uploadError = true
      }

      if(uploadError) {
        message.error(errorStr)
      }
      const detailValues = await this.formRef.current.validateFields();

      if(!uploadError) {
        this.createProduct(uploadData, detailValues)
      }
    } catch (error) {}
  }

  render() {
    return  (
        <Modal
        title={"Product Details"}
        visible={this.props.isOpen}
        onOk={() => this.submit()}
        onCancel={() => this.props.isCreateProductModalOpen(false)}
        destroyOnClose={true}
        cancelText="CANCEL"
        okText="CREATE"
        okButtonProps={{
          disabled: this.state.isLoading,
          className: "bitreel-button-primary",
        }}
        cancelButtonProps={{
          disabled: this.state.isLoading,
          className: "bitreel-button",
        }}
        closable={!this.state.isLoading}
        maskClosable={!this.state.isLoading}
        width={1000}

        >
      <Spin spinning={this.state.isLoading}>
        <Row align="middle" justify="space-around">
          <Col span={10}>
            <ProductDetails descriptionHeight={10} urlHeight={2} formRef={this.formRef}/>
          </Col>
          <Col span={10}>
            <Form layout={"vertical"} ref={this.uploadRef}>
              <Form.Item
                  label="Product Thumbnail"
                  name="thumbnail"
                  rules={[
                    { required: true, message: "Please add Thumbnail" },
                  ]}
                  valuePropName="file"
              >
                <Dragger
                    style={{ backgroundColor: "white" }}
                    name="thumbnail"
                    multiple={false}
                    accept=".png,.jpg"
                    beforeUpload={(file) => {return false}}
                    maxCount={1}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadSvg />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag thumbnail file to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item
                  label="Product Images"
                  name="images"
                  rules={[
                    { required: false, message: "Please add image" },
                  ]}
                  valuePropName="file"
              >
                  <Dragger
                      style={{ backgroundColor: "white" }}
                      multiple={true}
                      name="images"
                      accept=".png,.jpg"
                      beforeUpload={(file) => {return false}}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadSvg />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag image files to this area to upload
                    </p>
                  </Dragger>
              </Form.Item>
              <Form.Item
                  label="Reference Files"
                  name="files"
                  rules={[
                    { required: false, message: "Please add files" },
                  ]}
                  valuePropName="file"
              >
                <Dragger
                    style={{ backgroundColor: "white" }}
                    multiple={true}
                    name="files"
                    beforeUpload={(file) => {return false}}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadSvg />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag reference files to this area to upload
                  </p>
                </Dragger>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </Modal>);
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderData: state.companiesData.orderData,
    isOpen: state.product.isCreateProductModalOpen,
      openedProjects: state.projects.openedProjects,
      selectedOrder: state.orders.selectedOrder
  };
};

export default connect(mapStateToProps, {
  isCreateProductModalOpen,
  getCompanyRequest,
  createItem,
  createThumbnailRequest,
  uploadFileLocker,
})(withRouter(CreateProductModal));
