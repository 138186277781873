import React, { Component } from "react";
import { Layout, Spin } from "antd";
import { withRouter } from "react-router";
import SiderMenu from "../admin/container/siderMenu";
import AdminContent from "../admin/adminContent";
import ClientContent from "./clientContent";
const { Content } = Layout;
class Home extends Component {
  render() {
    const { auth } = this.props;
    return (
      <>
        {auth().user.admin ? (
          <>
            <SiderMenu auth={auth} />
            <Content className="contentRightSide">
              <AdminContent auth={auth} />
            </Content>
          </>
        ) : (
          <>
            <SiderMenu auth={auth} />
            <Content className="contentRightSide">
              <ClientContent auth={auth} />
            </Content>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Home);
