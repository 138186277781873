import React, { Component } from "react";
import { withRouter } from "react-router";
import { Input, Modal, Spin, Form } from "antd";
import { connect } from "react-redux";
import "@google/model-viewer";

import "../productView/productDetails.less";
import {
    editItem,
    openProductDetailModal,
} from "../redux/products/productActions";
import ProductDetails from "./productDetails";

class ProductDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.formRef = React.createRef();

        this.saveEdits = this.saveEdits.bind(this);
    }

    async saveEdits() {
        try {
            const values = await this.formRef.current.validateFields();
            this.props
                .editItem(this.props.auth().token, values, this.props.itemData.id)
                .then((res) => {
                    this.props.openProductDetailModal(false);
                });
        } catch (error) {}
    }
    render() {
        return (
            <Modal
                title={"Product Details"}
                visible={this.props.isOpen}
                onOk={() => this.saveEdits()}
                onCancel={() => this.props.openProductDetailModal(false)}
                destroyOnClose={true}
                cancelText="CANCEL"
                okText="SAVE EDITS"
                okButtonProps={{
                    disabled: this.props.isLoading,
                    className: "bitreel-button-primary",
                }}
                cancelButtonProps={{
                    disabled: this.props.isLoading,
                    className: "bitreel-button",
                }}
                closable={!this.props.isLoading}
                maskClosable={!this.props.isLoading}
            >
                <Spin spinning={this.props.isLoading}>
                    <ProductDetails formRef={this.formRef}/>
                </Spin>
            </Modal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        itemData: state.companiesData.itemData,
        isOpen: state.product.openProductDetailModal,
        isLoading: state.product.isUpdateLoading,
    };
};

export default connect(mapStateToProps, {
    openProductDetailModal,
    editItem,
})(withRouter(ProductDetailsModal));
