import produce from "immer";

const INITIAL_STATE = {
  isFetchingCompanies: false,
  isFetchingCompanyInfo: false
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_FETCHING_COMPANIES":
      draft["isFetchingCompanies"] = action.isFetchingCompanies;
      break;
      case "IS_FETCHING_COMPANY_INFO":
        draft["isFetchingCompanyInfo"] = action.isFetchingCompanyInfo;
        break;
    default:
      break;
  }

  return draft;
});
