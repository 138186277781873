import produce from "immer";

const INITIAL_STATE = {
  isCreateProjectLoading: false,
  isCreateProjectModalOpen: false,
  openedProjects: []
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_PROJECTS":
      draft.openedProjects = action.openedProjects;
      break;
    case "IS_CREATE_PROJECT_LOADING":
      draft.isCreateProjectLoading = action.isCreateProjectLoading;
      break;
    case "OPEN_CREATE_PROJECT_MODAL":
      draft.isCreateProjectModalOpen = action.isCreateProjectModalOpen;
      break;
    default:
      break;
  }

  return draft;
});
