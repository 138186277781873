import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { Spin, Modal, Upload, Typography } from "antd";
import {
  isUpdateLoading,
  editItem,
  openEditProductModal,
  editThumbnailRequest,
  createThumbnailRequest,
} from "../../redux/products/productActions";
import ProductDetails from "../../productView/productDetails";
import { getCompanyRequest } from "../../redux/persist/companiesActions";
import CloudUploadSvg from "./../../svg/cloudUploadSvg";

const { Text } = Typography;
const { Dragger } = Upload;

class ProductDetailWithTNModal extends Component {
  constructor(props) {
    super(props);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.itemFormRef = React.createRef();
  }

  state = {
    thumbnail: null,
  };
  beforeUpload(file) {
    this.setState({ ...this.state, thumbnail: file });

    return false;
  }

  onItemEditFinish = () => {
    const {
      editItem,
      auth,
      itemData,
      openEditProductModal,
      editThumbnailRequest,
      createThumbnailRequest,
      getCompanyRequest,
      match,
    } = this.props;
    const values = this.itemFormRef.current.getFieldsValue();

    editItem(auth().token, values, itemData.id).then((res) => {
      //update thumbnail if exist
      if (!!this.state.thumbnail) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const thumbnailData = e.target.result;
          const req = itemData.thumbnail_id
            ? editThumbnailRequest(auth().token, itemData.thumbnail_id, {
                item_id: itemData.id,
                thumbnail: thumbnailData,
              })
            : createThumbnailRequest(auth().token, {
                item_id: itemData.id,
                thumbnail: thumbnailData,
              });
          reader.readAsDataURL(this.state.thumbnail);
          req.then(() => {
            openEditProductModal(false);
            getCompanyRequest(auth().token, match.params.cid);
          });
        };
      } else {
        openEditProductModal(false);
        getCompanyRequest(auth().token, match.params.cid);
      }
    });
  };

  render() {
    const {
      openEditProductModal,
      isEditProductModalOpen,
      isLoading,
    } = this.props;
    return (
      <Modal
        title={"Edit Item"}
        visible={isEditProductModalOpen}
        onOk={this.onItemEditFinish}
        onCancel={() => openEditProductModal(false)}
        destroyOnClose={true}
        cancelText="Close"
        okText="save edits"
        okButtonProps={{
          disabled: isLoading,
          className: "bitreel-button-primary",
        }}
        cancelButtonProps={{ disabled: isLoading, className: "bitreel-button" }}
        closable={!isLoading}
        maskClosable={!isLoading}
      >
        <Spin spinning={isLoading}>
          <ProductDetails formRef={this.itemFormRef} />
          <Text>Thumbnail:</Text>
          <Dragger
            style={{ backgroundColor: "white" }}
            name="file"
            multiple={true}
            accept=".png,.jpg"
            beforeUpload={(file) => this.beforeUpload(file)}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <CloudUploadSvg />
            </p>
            <p className="ant-upload-text">
              Click or drag thumbnail file to this area to upload
            </p>
          </Dragger>
        </Spin>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companies,
    isEditProductModalOpen: state.product.isEditProductModalOpen,
    isLoading:
      state.product.isUpdateLoading || state.product.isThumbnailLoading,
    itemData: state.companiesData.itemData,
  };
};

export default connect(mapStateToProps, {
  openEditProductModal,
  createThumbnailRequest,
  editThumbnailRequest,
  isUpdateLoading,
  getCompanyRequest,
  editItem,
})(withRouter(ProductDetailWithTNModal));
