import * as React from "react"

function ChairSvg(props) {
  return (
    <svg
      width={66}
      height={66}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.254 17.633a6.816 6.816 0 00-4.407 1.608v-9.002c0-5.502-4.474-9.975-9.976-9.975H22.226c-5.501 0-9.975 4.473-9.975 9.975v8.99a6.834 6.834 0 00-4.506-1.695 6.885 6.885 0 00-6.88 6.88v28.7a1.2 1.2 0 001.203 1.203h10.085v10.216a1.2 1.2 0 001.203 1.203 1.2 1.2 0 001.203-1.203V54.36l36.881.044v10.117a1.2 1.2 0 001.203 1.203 1.2 1.2 0 001.203-1.203V54.405h10.085a1.2 1.2 0 001.203-1.203v-28.69a6.885 6.885 0 00-6.88-6.88zm-43.597-7.394c0-4.167 3.391-7.569 7.57-7.569H43.87c4.168 0 7.57 3.39 7.57 7.569v13.41c-.034.284-.066.58-.066.874 0 .132.022.252.065.361v9.943H14.657V10.239zM3.271 24.414a4.479 4.479 0 014.474-4.473 4.472 4.472 0 014.473 4.473c0 .098.011.197.033.284v11.047a1.19 1.19 0 00-.033.285v15.88H3.271V24.415zm11.354 12.819h36.75v14.776l-36.75-.054V37.233zm48.103 14.776h-8.936V36.391c.033-.12.065-.23.065-.361V23.8a4.47 4.47 0 014.408-3.762 4.479 4.479 0 014.474 4.473c-.011 0-.011 27.497-.011 27.497z"
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={33}
          y1={0.264}
          x2={33}
          y2={65.736}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#34D2E4" />
          <stop offset={1} stopColor="#0091FB" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ChairSvg
