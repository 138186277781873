import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";

import { Spin, Modal, Form, Input, Select } from "antd";

import {
  createOrder,
  setOrder,
} from "../../redux/persist/companiesActions";
import { FORM_LAYOUT } from "../../constants/constantsUI";
import { openCreateOrderModal } from "../../redux/orders/orderActions";

class CreateOrderModal extends Component {
  orderFormRef = React.createRef();



  onOrderFinish = async () => {
    const {createOrder, auth, openCreateOrderModal} = this.props;
    const data = await this.orderFormRef.current.validateFields();

    createOrder(auth().token, data).then(() => {
      openCreateOrderModal(false);
    });
  };

  onFinishFailed = () => {
    console.log("Bad input!");
  };

  getProjectOptions(projects) {
    const { Option } = Select;

    const options = [];
    _.forEach(projects, (project) => {
      options.push(<Option key={project.id}>{project.name}</Option>);
    });
    return options;
  }


  render() {
    const {
      companies,
      openCreateOrderModal,
      isCreateOrderModalOpen,
      isLoading,
    } = this.props;

    const { companyDetail } = companies;
    const { projects } = companyDetail;

    return (
      <>
        <Modal
          title={"Create Order"}
          visible={isCreateOrderModalOpen}
          onOk={this.onOrderFinish}
          onCancel={() => openCreateOrderModal(false)}
          destroyOnClose={true}
          cancelText="Cancel"
          okText="Create"
          okButtonProps={{
            disabled: isLoading,
            className: "bitreel-button-primary",
          }}
          cancelButtonProps={{
            disabled: isLoading,
            className: "bitreel-button",
          }}
          closable={!isLoading}
          maskClosable={!isLoading}
        >
          <Spin spinning={isLoading}>
            <Form
              layout={"vertical"}
              name="order"
              onFinish={this.onOrderFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.orderFormRef}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input order name - unique per project!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Project"
                name="project_id"
                rules={[{ required: true, message: "Please select project!" }]}
              >
                <Select>{this.getProjectOptions(projects)}</Select>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    companies: state.companiesData,
    isCreateOrderModalOpen: state.orders.isCreateOrderModalOpen,
    isLoading: state.orders.isCreateOrderLoading,
  };
};

export default connect(mapStateToProps, {
  openCreateOrderModal,
  createOrder,
  setOrder,
})(withRouter(CreateOrderModal));
