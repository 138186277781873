import produce from "immer";

const INITIAL_STATE = {
  isCreateOrderModalOpen: false,
  isCreateOrderLoading: false,
  selectedOrder: []
};

export default produce((draft = INITIAL_STATE, action) => {
  switch (action.type) {
    case "IS_CREATE_ORDER_LOADING":
      draft.isCreateOrderLoading = action.isCreateOrderLoading;
      break;
    case "UPDATE_SELECTED_ORDER":
      draft.selectedOrder = action.selectedOrder;
      break;
    case "OPEN_CREATE_ORDER_MODAL":
      draft.isCreateOrderModalOpen = action.isCreateOrderModalOpen;
      break;
    default:
      break;
  }

  return draft;
});
