import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ProductPanel from "./productPanel";
import FileLocker from "./fileLocker/FileLocker";
import { Button, Layout, Row, Col } from "antd";
import { getItemRequest } from "../redux/persist/companiesActions";
import ProductComment from "./productComment";

const { Content } = Layout;

import "./productInfo.less";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

class ProductInfo extends Component {
  constructor(props) {
    super(props);
    this.props.getItemRequest(
      this.props.auth().token,
      this.props.match.params.pid
    );
  }

  render() {
    const { auth } = this.props;
    return (
      <Content style={{marginTop: 60}} className="product_info">
        <Button
          icon={<LeftOutlined />}
          className="product-back-button"
          onClick={() => this.props.history.goBack()}
        >
          Back
        </Button>
        <div className="product_info_main">
          <ProductPanel
            auth={this.props.auth}
            user={this.props.auth().user}
          />
          <ProductComment auth={this.props.auth} />
          <Row>
            <Col span={24}>
              <FileLocker auth={auth} />
            </Col>
          </Row>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {
  getItemRequest,
})(withRouter(ProductInfo));
